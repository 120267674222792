@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #575757;
  text-align: left;
  background-color: #f0f2f5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 20px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3f9ce8;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1568ac;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "SFMono-Regular", Consolas, Menlo, Monaco, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  font-weight: 700;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 20px;
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  line-height: 1.2;
  color: #171717;
}

h1, .h1 {
  font-size: 2.571428571rem;
}

h2, .h2 {
  font-size: 2.142857142rem;
}

h3, .h3 {
  font-size: 1.857142857rem;
}

h4, .h4 {
  font-size: 1.571428571rem;
}

h5, .h5 {
  font-size: 1.285714286rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.5rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e4e7ed;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f0f2f5;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #575757;
}
.table th,
.table td {
  padding: 10px;
  vertical-align: top;
  border-top: 1px solid #e4e7ed;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e4e7ed;
}
.table tbody + tbody {
  border-top: 2px solid #e4e7ed;
}

.table-sm th,
.table-sm td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #e4e7ed;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e4e7ed;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.table-hover tbody tr:hover {
  color: #575757;
  background-color: rgba(0, 0, 0, 0.04);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c9e3f9;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9bccf3;
}

.table-hover .table-primary:hover {
  background-color: #b2d7f6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2d7f6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e3f1d4;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #cce4af;
}

.table-hover .table-success:hover {
  background-color: #d6ebc1;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d6ebc1;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c2eff5;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8ee1ec;
}

.table-hover .table-info:hover {
  background-color: #ace9f1;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #ace9f1;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff0c3;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe38f;
}

.table-hover .table-warning:hover {
  background-color: #ffeaaa;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffeaaa;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbcfce;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7a6a4;
}

.table-hover .table-danger:hover {
  background-color: #f9b8b6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f9b8b6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.04);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.04);
}

.table .thead-dark th {
  color: #e4e7ed;
  background-color: #343a40;
  border-color: #3f474e;
}
.table .thead-light th {
  color: #575757;
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}

.table-dark {
  color: #e4e7ed;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3f474e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
.table-dark.table-hover tbody tr:hover {
  color: #e4e7ed;
  background-color: rgba(0, 0, 0, 0.18);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.428572em + 0.8571428rem + 2px);
  padding: 0.4285714rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428572;
  color: #575757;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #575757;
}
.form-control:focus {
  color: #575757;
  background-color: #fff;
  border-color: #97a5ba;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #575757;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.4285714rem + 1px);
  padding-bottom: calc(0.4285714rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.428572;
}

.col-form-label-lg {
  padding-top: calc(0.64285714rem + 1px);
  padding-bottom: calc(0.64285714rem + 1px);
  font-size: 1.14285714rem;
  line-height: 1.25;
}

.col-form-label-sm {
  padding-top: calc(0.3571429rem + 1px);
  padding-bottom: calc(0.3571429rem + 1px);
  font-size: 1rem;
  line-height: 1.1428572;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.4285714rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.428572;
  color: #575757;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.1428572em + 0.7142858rem + 2px);
  padding: 0.3571429rem 0.6429rem;
  font-size: 1rem;
  line-height: 1.1428572;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.25em + 1.28571428rem + 2px);
  padding: 0.64285714rem 1.286rem;
  font-size: 1.14285714rem;
  line-height: 1.25;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 16px;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #9ccc65;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 4px 10px;
  margin-top: 0.1rem;
  font-size: 0.928571429rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(156, 204, 101, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #9ccc65;
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #9ccc65;
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #9ccc65;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #9ccc65;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #daecc5;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #b5d98b;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #9ccc65;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #9ccc65;
}

.is-valid .form-control {
  border-color: #9ccc65;
}
.is-valid .form-check-input ~ .form-check-label {
  color: #9ccc65;
}
.is-valid .custom-control-input ~ .custom-control-label {
  color: #9ccc65;
}
.is-valid .custom-control-input ~ .custom-control-label::before {
  background-color: rgba(156, 204, 101, 0.25);
}
.is-valid .valid-feedback,
.is-valid .valid-tooltip {
  display: block;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ef5350;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 4px 10px;
  margin-top: 0.1rem;
  font-size: 0.928571429rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(239, 83, 80, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ef5350;
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ef5350;
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ef5350;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef5350;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #fac6c5;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f3817f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef5350;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef5350;
}

.is-invalid .form-control {
  border-color: #ef5350;
}
.is-invalid .form-check-input ~ .form-check-label {
  color: #ef5350;
}
.is-invalid .custom-control-input ~ .custom-control-label {
  color: #ef5350;
}
.is-invalid .custom-control-input ~ .custom-control-label::before {
  background-color: rgba(239, 83, 80, 0.25);
}
.is-invalid .invalid-feedback,
.is-invalid .invalid-tooltip {
  display: block;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #575757;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.57142857rem 1rem;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #575757;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3f9ce8;
  border-color: #197ed1;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #197ed1;
  border-color: #1463a3;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #3f9ce8;
  border-color: #197ed1;
}
.btn-primary:not([disabled]):not(.disabled):active {
  background-color: #3f9ce8;
  border-color: #197ed1;
}
.btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #197ed1;
  background-image: none;
  border-color: #1463a3;
}
.btn-primary.btn-noborder {
  border-color: #3f9ce8;
}
.btn-primary.btn-noborder:hover, .btn-primary.btn-noborder:focus, .btn-primary.btn-noborder.focus {
  border-color: #197ed1;
}
.btn-primary.btn-noborder.disabled, .btn-primary.btn-noborder:disabled, .btn-primary.btn-noborder:not([disabled]):not(.disabled):active, .btn-primary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #3f9ce8;
}
.show > .btn-primary.btn-noborder.dropdown-toggle {
  border-color: #197ed1;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #50565c;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #50565c;
  border-color: #383d41;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #6c757d;
  border-color: #50565c;
}
.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: #6c757d;
  border-color: #50565c;
}
.btn-secondary:not([disabled]):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: #50565c;
  background-image: none;
  border-color: #383d41;
}
.btn-secondary.btn-noborder {
  border-color: #6c757d;
}
.btn-secondary.btn-noborder:hover, .btn-secondary.btn-noborder:focus, .btn-secondary.btn-noborder.focus {
  border-color: #50565c;
}
.btn-secondary.btn-noborder.disabled, .btn-secondary.btn-noborder:disabled, .btn-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #6c757d;
}
.show > .btn-secondary.btn-noborder.dropdown-toggle {
  border-color: #50565c;
}

.btn-success {
  color: #fff;
  background-color: #9ccc65;
  border-color: #7eb73d;
}
.btn-success:hover, .btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #7eb73d;
  border-color: #649130;
}
.btn-success.disabled, .btn-success:disabled {
  background-color: #9ccc65;
  border-color: #7eb73d;
}
.btn-success:not([disabled]):not(.disabled):active {
  background-color: #9ccc65;
  border-color: #7eb73d;
}
.btn-success:not([disabled]):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  background-color: #7eb73d;
  background-image: none;
  border-color: #649130;
}
.btn-success.btn-noborder {
  border-color: #9ccc65;
}
.btn-success.btn-noborder:hover, .btn-success.btn-noborder:focus, .btn-success.btn-noborder.focus {
  border-color: #7eb73d;
}
.btn-success.btn-noborder.disabled, .btn-success.btn-noborder:disabled, .btn-success.btn-noborder:not([disabled]):not(.disabled):active, .btn-success.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #9ccc65;
}
.show > .btn-success.btn-noborder.dropdown-toggle {
  border-color: #7eb73d;
}

.btn-info {
  color: #fff;
  background-color: #26c6da;
  border-color: #1c97a6;
}
.btn-info:hover, .btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #1c97a6;
  border-color: #15707b;
}
.btn-info.disabled, .btn-info:disabled {
  background-color: #26c6da;
  border-color: #1c97a6;
}
.btn-info:not([disabled]):not(.disabled):active {
  background-color: #26c6da;
  border-color: #1c97a6;
}
.btn-info:not([disabled]):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  background-color: #1c97a6;
  background-image: none;
  border-color: #15707b;
}
.btn-info.btn-noborder {
  border-color: #26c6da;
}
.btn-info.btn-noborder:hover, .btn-info.btn-noborder:focus, .btn-info.btn-noborder.focus {
  border-color: #1c97a6;
}
.btn-info.btn-noborder.disabled, .btn-info.btn-noborder:disabled, .btn-info.btn-noborder:not([disabled]):not(.disabled):active, .btn-info.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #26c6da;
}
.show > .btn-info.btn-noborder.dropdown-toggle {
  border-color: #1c97a6;
}

.btn-warning {
  color: #fff;
  background-color: #ffca28;
  border-color: #eab000;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #eab000;
  border-color: #b78a00;
}
.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffca28;
  border-color: #eab000;
}
.btn-warning:not([disabled]):not(.disabled):active {
  background-color: #ffca28;
  border-color: #eab000;
}
.btn-warning:not([disabled]):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  background-color: #eab000;
  background-image: none;
  border-color: #b78a00;
}
.btn-warning.btn-noborder {
  border-color: #ffca28;
}
.btn-warning.btn-noborder:hover, .btn-warning.btn-noborder:focus, .btn-warning.btn-noborder.focus {
  border-color: #eab000;
}
.btn-warning.btn-noborder.disabled, .btn-warning.btn-noborder:disabled, .btn-warning.btn-noborder:not([disabled]):not(.disabled):active, .btn-warning.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #ffca28;
}
.show > .btn-warning.btn-noborder.dropdown-toggle {
  border-color: #eab000;
}

.btn-danger {
  color: #fff;
  background-color: #ef5350;
  border-color: #ea1c18;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #ea1c18;
  border-color: #bd1511;
}
.btn-danger.disabled, .btn-danger:disabled {
  background-color: #ef5350;
  border-color: #ea1c18;
}
.btn-danger:not([disabled]):not(.disabled):active {
  background-color: #ef5350;
  border-color: #ea1c18;
}
.btn-danger:not([disabled]):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  background-color: #ea1c18;
  background-image: none;
  border-color: #bd1511;
}
.btn-danger.btn-noborder {
  border-color: #ef5350;
}
.btn-danger.btn-noborder:hover, .btn-danger.btn-noborder:focus, .btn-danger.btn-noborder.focus {
  border-color: #ea1c18;
}
.btn-danger.btn-noborder.disabled, .btn-danger.btn-noborder:disabled, .btn-danger.btn-noborder:not([disabled]):not(.disabled):active, .btn-danger.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #ef5350;
}
.show > .btn-danger.btn-noborder.dropdown-toggle {
  border-color: #ea1c18;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #d4dae1;
}
.btn-light:hover, .btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #d4dae1;
  border-color: #b7c1cb;
}
.btn-light.disabled, .btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #d4dae1;
}
.btn-light:not([disabled]):not(.disabled):active {
  background-color: #f8f9fa;
  border-color: #d4dae1;
}
.btn-light:not([disabled]):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  background-color: #d4dae1;
  background-image: none;
  border-color: #b7c1cb;
}
.btn-light.btn-noborder {
  border-color: #f8f9fa;
}
.btn-light.btn-noborder:hover, .btn-light.btn-noborder:focus, .btn-light.btn-noborder.focus {
  border-color: #d4dae1;
}
.btn-light.btn-noborder.disabled, .btn-light.btn-noborder:disabled, .btn-light.btn-noborder:not([disabled]):not(.disabled):active, .btn-light.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #f8f9fa;
}
.show > .btn-light.btn-noborder.dropdown-toggle {
  border-color: #d4dae1;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #191b1e;
}
.btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #191b1e;
  border-color: #020202;
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #343a40;
  border-color: #191b1e;
}
.btn-dark:not([disabled]):not(.disabled):active {
  background-color: #343a40;
  border-color: #191b1e;
}
.btn-dark:not([disabled]):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  background-color: #191b1e;
  background-image: none;
  border-color: #020202;
}
.btn-dark.btn-noborder {
  border-color: #343a40;
}
.btn-dark.btn-noborder:hover, .btn-dark.btn-noborder:focus, .btn-dark.btn-noborder.focus {
  border-color: #191b1e;
}
.btn-dark.btn-noborder.disabled, .btn-dark.btn-noborder:disabled, .btn-dark.btn-noborder:not([disabled]):not(.disabled):active, .btn-dark.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #343a40;
}
.show > .btn-dark.btn-noborder.dropdown-toggle {
  border-color: #191b1e;
}

.btn-outline-primary {
  color: #3f9ce8;
  background-color: transparent;
  background-image: none;
  border-color: #3f9ce8;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
  color: #fff;
  background-color: #3f9ce8;
  border-color: #3f9ce8;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3f9ce8;
  background-color: transparent;
}
.btn-outline-primary:not([disabled]):not(.disabled):active {
  color: #3f9ce8;
  background-color: transparent;
  border-color: #3f9ce8;
}
.btn-outline-primary:not([disabled]):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3f9ce8;
  border-color: #3f9ce8;
}
.btn-outline-primary.btn-noborder {
  border-color: transparent;
}
.btn-outline-primary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-primary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not([disabled]):not(.disabled):active {
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
}
.btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.btn-noborder {
  border-color: transparent;
}
.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-success {
  color: #9ccc65;
  background-color: transparent;
  background-image: none;
  border-color: #9ccc65;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success.focus {
  color: #fff;
  background-color: #9ccc65;
  border-color: #9ccc65;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #9ccc65;
  background-color: transparent;
}
.btn-outline-success:not([disabled]):not(.disabled):active {
  color: #9ccc65;
  background-color: transparent;
  border-color: #9ccc65;
}
.btn-outline-success:not([disabled]):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #9ccc65;
  border-color: #9ccc65;
}
.btn-outline-success.btn-noborder {
  border-color: transparent;
}
.btn-outline-success.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-success.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-info {
  color: #26c6da;
  background-color: transparent;
  background-image: none;
  border-color: #26c6da;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info.focus {
  color: #fff;
  background-color: #26c6da;
  border-color: #26c6da;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #26c6da;
  background-color: transparent;
}
.btn-outline-info:not([disabled]):not(.disabled):active {
  color: #26c6da;
  background-color: transparent;
  border-color: #26c6da;
}
.btn-outline-info:not([disabled]):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #26c6da;
  border-color: #26c6da;
}
.btn-outline-info.btn-noborder {
  border-color: transparent;
}
.btn-outline-info.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-info.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-warning {
  color: #ffca28;
  background-color: transparent;
  background-image: none;
  border-color: #ffca28;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning.focus {
  color: #fff;
  background-color: #ffca28;
  border-color: #ffca28;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffca28;
  background-color: transparent;
}
.btn-outline-warning:not([disabled]):not(.disabled):active {
  color: #ffca28;
  background-color: transparent;
  border-color: #ffca28;
}
.btn-outline-warning:not([disabled]):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffca28;
  border-color: #ffca28;
}
.btn-outline-warning.btn-noborder {
  border-color: transparent;
}
.btn-outline-warning.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-warning.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-danger {
  color: #ef5350;
  background-color: transparent;
  background-image: none;
  border-color: #ef5350;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger.focus {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ef5350;
  background-color: transparent;
}
.btn-outline-danger:not([disabled]):not(.disabled):active {
  color: #ef5350;
  background-color: transparent;
  border-color: #ef5350;
}
.btn-outline-danger:not([disabled]):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
}
.btn-outline-danger.btn-noborder {
  border-color: transparent;
}
.btn-outline-danger.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-danger.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light.focus {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not([disabled]):not(.disabled):active {
  color: #f8f9fa;
  background-color: transparent;
  border-color: #f8f9fa;
}
.btn-outline-light:not([disabled]):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.btn-noborder {
  border-color: transparent;
}
.btn-outline-light.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-light.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark.focus {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not([disabled]):not(.disabled):active {
  color: #343a40;
  background-color: transparent;
  border-color: #343a40;
}
.btn-outline-dark:not([disabled]):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.btn-noborder {
  border-color: transparent;
}
.btn-outline-dark.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-dark.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #3f9ce8;
  text-decoration: none;
}
.btn-link:hover {
  color: #1568ac;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.64285714rem 1.285714rem;
  font-size: 1.285714rem;
  line-height: 20px;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3571429rem 0.64285714rem;
  font-size: 0.9286rem;
  line-height: 16px;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 8px 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #575757;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ed;
  border-radius: 2px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e4e7ed;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 10px;
  clear: both;
  font-weight: 400;
  color: #575757;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f0f2f5;
}
.dropdown-item.active, .dropdown-item:active {
  color: #575757;
  text-decoration: none;
  background-color: #e4e7ed;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 8px 10px;
  margin-bottom: 0;
  font-size: 0.928571429rem;
  color: #171717;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 5px 10px;
  color: #575757;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.482142855rem;
  padding-left: 0.482142855rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9642855rem;
  padding-left: 0.9642855rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4285714rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428572;
  color: #575757;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f7f9;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.25em + 1.28571428rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.64285714rem 1.286rem;
  font-size: 1.14285714rem;
  line-height: 1.25;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.1428572em + 0.7142858rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.3571429rem 0.6429rem;
  font-size: 1rem;
  line-height: 1.1428572;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.643rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.143rem;
  height: 1.3215rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3f9ce8;
  background-color: #3f9ce8;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f0f2f5, 0 0 0 3px #3f9ce8;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #97a5ba;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #deeefb;
  border-color: #deeefb;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.1785rem;
  left: -1.643rem;
  display: block;
  width: 1.143rem;
  height: 1.143rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.1785rem;
  left: -1.643rem;
  display: block;
  width: 1.143rem;
  height: 1.143rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3f9ce8;
  background-color: #3f9ce8;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5);
}

.custom-switch {
  padding-left: 2.50025rem;
}
.custom-switch .custom-control-label::before {
  left: -2.50025rem;
  width: 2.00025rem;
  pointer-events: all;
  border-radius: 0.5715rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.1785rem + 2px);
  left: calc(-2.50025rem + 2px);
  width: calc(1.143rem - 4px);
  height: calc(1.143rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5715rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.85725rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(63, 156, 232, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.428572em + 0.8571428rem + 2px);
  padding: 0.4285714rem 2rem 0.4285714rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428572;
  color: #575757;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #b1d7f6;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(177, 215, 246, 0.5);
}
.custom-select:focus::-ms-value {
  color: #575757;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #575757;
}

.custom-select-sm {
  height: calc(1.1428572em + 0.7142858rem + 2px);
  padding-top: 0.3571429rem;
  padding-bottom: 0.3571429rem;
  padding-left: 0.6429rem;
  font-size: 1rem;
}

.custom-select-lg {
  height: calc(1.25em + 1.28571428rem + 2px);
  padding-top: 0.64285714rem;
  padding-bottom: 0.64285714rem;
  padding-left: 1.286rem;
  font-size: 1.14285714rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.428572em + 0.8571428rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.428572em + 0.8571428rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #97a5ba;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.428572em + 0.8571428rem + 2px);
  padding: 0.4285714rem 1rem;
  font-weight: 400;
  line-height: 1.428572;
  color: #575757;
  background-color: #fff;
  border: 1px solid #d4dae3;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.428572em + 0.8571428rem);
  padding: 0.4285714rem 1rem;
  line-height: 1.428572;
  color: #575757;
  content: "Browse";
  background-color: #f6f7f9;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f0f2f5, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f0f2f5, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f0f2f5, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3f9ce8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #deeefb;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3f9ce8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #deeefb;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3f9ce8;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #deeefb;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e4e7ed;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e4e7ed;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #575757;
  background-color: #fff;
  border-color: #e4e7ed;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3f9ce8;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 9px 14px;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f0f2f5;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #171717;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.57142857rem 0.71428571rem;
  margin-left: -1px;
  line-height: 1.2;
  color: #171717;
  background-color: #f0f2f5;
  border: 1px solid #f0f2f5;
}
.page-link:hover {
  z-index: 2;
  color: #171717;
  text-decoration: none;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3f9ce8;
  border-color: #3f9ce8;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #fff;
}

.pagination-lg .page-link {
  padding: 0.64285714rem 1.42857143rem;
  font-size: 1.285714286rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.35714286rem 0.5rem;
  font-size: 0.928571429rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 3px 5px;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3f9ce8;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1a84da;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(63, 156, 232, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #9ccc65;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #83bf3f;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 204, 101, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #26c6da;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #1e9faf;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 198, 218, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #ffca28;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #fff;
  background-color: #f4b800;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 202, 40, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #ef5350;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #eb2521;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 83, 80, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 24px;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #215179;
  background-color: #d9ebfa;
  border-color: #c9e3f9;
}
.alert-primary hr {
  border-top-color: #b2d7f6;
}
.alert-primary .alert-link {
  color: #163651;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #516a35;
  background-color: #ebf5e0;
  border-color: #e3f1d4;
}
.alert-success hr {
  border-top-color: #d6ebc1;
}
.alert-success .alert-link {
  color: #374824;
}

.alert-info {
  color: #146771;
  background-color: #d4f4f8;
  border-color: #c2eff5;
}
.alert-info hr {
  border-top-color: #ace9f1;
}
.alert-info .alert-link {
  color: #0c4046;
}

.alert-warning {
  color: #856915;
  background-color: #fff4d4;
  border-color: #fff0c3;
}
.alert-warning hr {
  border-top-color: #ffeaaa;
}
.alert-warning .alert-link {
  color: #59460e;
}

.alert-danger {
  color: #7c2b2a;
  background-color: #fcdddc;
  border-color: #fbcfce;
}
.alert-danger hr {
  border-top-color: #f9b8b6;
}
.alert-danger .alert-link {
  color: #561e1d;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 20px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 20px;
  overflow: hidden;
  line-height: 0;
  font-size: 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3f9ce8;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 20px 20px;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #575757;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #575757;
  text-decoration: none;
  background-color: #f6f7f9;
}
.list-group-item-action:active {
  color: #575757;
  background-color: #e4e7ed;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #e4e7ed;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3f9ce8;
  border-color: #3f9ce8;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #215179;
  background-color: #c9e3f9;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #215179;
  background-color: #b2d7f6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #215179;
  border-color: #215179;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #516a35;
  background-color: #e3f1d4;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #516a35;
  background-color: #d6ebc1;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #516a35;
  border-color: #516a35;
}

.list-group-item-info {
  color: #146771;
  background-color: #c2eff5;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #146771;
  background-color: #ace9f1;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #146771;
  border-color: #146771;
}

.list-group-item-warning {
  color: #856915;
  background-color: #fff0c3;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856915;
  background-color: #ffeaaa;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856915;
  border-color: #856915;
}

.list-group-item-danger {
  color: #7c2b2a;
  background-color: #fbcfce;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7c2b2a;
  background-color: #f9b8b6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7c2b2a;
  border-color: #7c2b2a;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 0 solid #e4e7ed;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 24px;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.42857143rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.17857143rem;
  border-top: 0 solid #e4e7ed;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.928571429rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #343a40;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #343a40;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #343a40;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #343a40;
}

.tooltip-inner {
  max-width: 200px;
  padding: 4px 10px;
  color: #fff;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.928571429rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ed;
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #e4e7ed;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #e4e7ed;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #e4e7ed;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #fff;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #e4e7ed;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 12px 10px;
  margin-bottom: 0;
  font-size: 1rem;
  color: #171717;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 10px 10px;
  color: #575757;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3f9ce8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1a84da !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #9ccc65 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #83bf3f !important;
}

.bg-info {
  background-color: #26c6da !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1e9faf !important;
}

.bg-warning {
  background-color: #ffca28 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f4b800 !important;
}

.bg-danger {
  background-color: #ef5350 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #eb2521 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3f9ce8 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #9ccc65 !important;
}

.border-info {
  border-color: #26c6da !important;
}

.border-warning {
  border-color: #ffca28 !important;
}

.border-danger {
  border-color: #ef5350 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: "SFMono-Regular", Consolas, Menlo, Monaco, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3f9ce8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1776c3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #9ccc65 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #76ac39 !important;
}

.text-info {
  color: #26c6da !important;
}

a.text-info:hover, a.text-info:focus {
  color: #1a8b99 !important;
}

.text-warning {
  color: #ffca28 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #dba500 !important;
}

.text-danger {
  color: #ef5350 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #de1814 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #575757 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #e4e7ed;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e4e7ed;
  }
}
html {
  font-size: 14px;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
strong {
  font-weight: 600;
}

a {
  transition: color 0.12s ease-out;
}
@media (min-width: 576px) {
  a.link-effect {
    position: relative;
  }
  a.link-effect::before {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #3f9ce8;
    visibility: hidden;
    transform: translateY(6px);
    transform-origin: 50% 50%;
    opacity: 0;
    transition: transform 0.12s ease-out, opacity 0.12s ease-out;
  }
}
a:hover.link-effect::before {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

p {
  line-height: 1.6;
  margin-bottom: 24px;
}

.nice-copy p,
p.nice-copy {
  line-height: 1.8;
  font-size: 1.142857143rem;
}

.nice-copy-story p,
p.nice-copy-story {
  line-height: 1.8;
  font-size: 1.285714286rem;
  color: #626262;
}

.nice-copy-story h2,
.nice-copy-story h3,
.nice-copy-story h4 {
  margin-top: 50px;
}

.font-size-h1 {
  font-size: 2.571428571rem;
}

.font-size-h2 {
  font-size: 2.142857142rem;
}

.font-size-h3 {
  font-size: 1.857142857rem;
}

.font-size-h4 {
  font-size: 1.571428571rem;
}

.font-size-h5 {
  font-size: 1.285714286rem;
}

.font-size-h6 {
  font-size: 1rem;
}

@media (max-width: 767.98px) {
  .display-1 {
    font-size: 3.429rem;
  }

  .display-2 {
    font-size: 3.143rem;
  }

  .display-3 {
    font-size: 2.857rem;
  }

  .display-4 {
    font-size: 2.571428571rem;
  }

  h1, .h1, .font-size-h1 {
    font-size: 2.142857142rem;
  }

  h2, .h2, .font-size-h2 {
    font-size: 1.857142857rem;
  }

  h3, .h3, .font-size-h3 {
    font-size: 1.571428571rem;
  }

  h4, .h4, .font-size-h4 {
    font-size: 1.285714286rem;
  }

  h5, .h5, .font-size-h5,
h6, .h6, .font-size-h6 {
    font-size: 1rem;
  }
}
.content-heading {
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 8px;
  font-size: 1.428571429rem;
  font-weight: 400;
  line-height: 28px;
  border-bottom: 1px solid #e4e7ed;
}
.content-heading small {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
}
@media (min-width: 768px) {
  .content-heading {
    margin-bottom: 25px;
    padding-top: 40px;
  }
  .content-heading small {
    margin-top: 0;
  }
}
.content-heading .dropdown {
  line-height: 1.5;
}

small,
.small {
  font-weight: inherit;
  color: #646464;
}

.row.gutters-tiny {
  margin-right: -3px;
  margin-left: -3px;
}
.row.gutters-tiny > .col,
.row.gutters-tiny > [class*=col-] {
  padding-left: 3px;
  padding-right: 3px;
}
.row.gutters-tiny .push,
.row.gutters-tiny .block {
  margin-bottom: 6px;
}

@media (max-width: 767.98px) {
  .row:not(.gutters-tiny):not(.no-gutters) {
    margin-right: -6px;
    margin-left: -6px;
  }
  .row:not(.gutters-tiny):not(.no-gutters) > .col,
.row:not(.gutters-tiny):not(.no-gutters) > [class*=col-] {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.row.row-deck > div {
  display: flex;
  align-items: stretch;
}
.row.row-deck > div > .block {
  min-width: 100%;
}

.table thead th {
  border-top: none;
  border-bottom: none;
  text-transform: uppercase;
}

.table-vcenter th,
.table-vcenter td {
  vertical-align: middle;
}

.table-responsive > .table.table-bordered thead th,
[class*=table-responsive-] > .table.table-bordered thead th {
  border-top: 1px solid #e4e7ed;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f0f2f5;
}

.table-hover .table-active:hover {
  background-color: #e1e5eb;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e1e5eb;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c8e2f8;
}

.table-hover .table-primary:hover {
  background-color: #b1d7f6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b1d7f6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #ebf5df;
}

.table-hover .table-success:hover {
  background-color: #dfefcc;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #dfefcc;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e3f4fc;
}

.table-hover .table-info:hover {
  background-color: #ccebfa;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #ccebfa;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf7e6;
}

.table-hover .table-warning:hover {
  background-color: #f9f0cf;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9f0cf;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fae9e8;
}

.table-hover .table-danger:hover {
  background-color: #f5d5d3;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5d5d3;
}

.js-table-checkable tbody tr,
.js-table-sections-header > tr {
  cursor: pointer;
}

.js-table-sections-header > tr > td:first-child > i {
  transition: transform 0.2s ease-in-out;
}
.js-table-sections-header + tbody {
  display: none;
}
.js-table-sections-header.show > tr > td:first-child > i {
  transform: rotate(90deg);
}
.js-table-sections-header.show + tbody {
  display: table-row-group;
}

label {
  font-weight: 600;
}

.invalid-feedback {
  font-size: 0.928571429rem;
}

.btn {
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
}
.btn.btn-square {
  border-radius: 0 !important;
}
.btn.btn-rounded {
  border-radius: 50px !important;
}
.btn .si {
  position: relative;
  top: 1px;
}

.btn.btn-hero {
  padding: 14px 38px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 3px;
  height: auto;
}
.btn.btn-hero.btn-sm, .btn-group-sm > .btn.btn-hero {
  padding: 11px 34px;
  font-size: 1rem;
  line-height: 16px;
  border-radius: 3px;
}
.btn.btn-hero.btn-lg, .btn-group-lg > .btn.btn-hero {
  padding: 15px 42px;
  font-size: 1.142857143rem;
  line-height: 20px;
  border-radius: 3px;
}

.btn.btn-circle {
  padding: 9px 0;
  font-size: 1rem;
  line-height: 1;
  border-radius: inherit;
  min-width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 50% !important;
}
.btn.btn-circle.btn-sm, .btn-group-sm > .btn.btn-circle {
  padding: 6px 0;
  font-size: 1rem;
  line-height: 1;
  border-radius: inherit;
  min-width: 28px;
  height: 28px;
}
.btn.btn-circle.btn-lg, .btn-group-lg > .btn.btn-circle {
  padding: 12px 0;
  font-size: 1rem;
  line-height: 1;
  border-radius: inherit;
  min-width: 40px;
  height: 40px;
}
.btn.btn-circle > .si {
  top: 0;
}

.btn-secondary {
  color: #212529;
  background-color: #f0f2f5;
  border-color: #cbd2dd;
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #cbd2dd;
  border-color: #adb8c8;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #f0f2f5;
  border-color: #cbd2dd;
}
.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: #f0f2f5;
  border-color: #cbd2dd;
}
.btn-secondary:not([disabled]):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: #cbd2dd;
  background-image: none;
  border-color: #adb8c8;
}
.btn-secondary.btn-noborder {
  border-color: #f0f2f5;
}
.btn-secondary.btn-noborder:hover, .btn-secondary.btn-noborder:focus, .btn-secondary.btn-noborder.focus {
  border-color: #cbd2dd;
}
.btn-secondary.btn-noborder.disabled, .btn-secondary.btn-noborder:disabled, .btn-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-secondary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: #f0f2f5;
}
.show > .btn-secondary.btn-noborder.dropdown-toggle {
  border-color: #cbd2dd;
}

.btn-outline-secondary {
  color: #697d9b;
  background-color: transparent;
  background-image: none;
  border-color: #697d9b;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
  color: #fff;
  background-color: #697d9b;
  border-color: #697d9b;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #697d9b;
  background-color: transparent;
}
.btn-outline-secondary:not([disabled]):not(.disabled):active {
  color: #697d9b;
  background-color: transparent;
  border-color: #697d9b;
}
.btn-outline-secondary:not([disabled]):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #697d9b;
  border-color: #697d9b;
}
.btn-outline-secondary.btn-noborder {
  border-color: transparent;
}
.btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled):active, .btn-outline-secondary.btn-noborder:not([disabled]):not(.disabled).active {
  border-color: transparent;
}

.btn-alt-primary {
  color: #125a96;
  background-color: #c8e2f8;
  border-color: #c8e2f8;
}
.btn-alt-primary:hover, .btn-alt-primary:focus, .btn-alt-primary.focus {
  color: #125a96;
  background-color: #9acbf3;
  border-color: #9acbf3;
  box-shadow: none;
}
.btn-alt-primary.disabled, .btn-alt-primary:disabled {
  background-color: #c8e2f8;
  border-color: #c8e2f8;
}
.btn-alt-primary:not([disabled]):not(.disabled):active {
  color: #125a96;
  background-color: #c8e2f8;
  border-color: #c8e2f8;
}
.btn-alt-primary:not([disabled]):not(.disabled).active, .show > .btn-alt-primary.dropdown-toggle {
  color: #125a96;
  background-color: #9acbf3;
  border-color: #9acbf3;
}

.btn-alt-secondary {
  color: #171717;
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}
.btn-alt-secondary:hover, .btn-alt-secondary:focus, .btn-alt-secondary.focus {
  color: #171717;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
  box-shadow: none;
}
.btn-alt-secondary.disabled, .btn-alt-secondary:disabled {
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}
.btn-alt-secondary:not([disabled]):not(.disabled):active {
  color: #171717;
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}
.btn-alt-secondary:not([disabled]):not(.disabled).active, .show > .btn-alt-secondary.dropdown-toggle {
  color: #171717;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-alt-success {
  color: #5c852c;
  background-color: #ebf5df;
  border-color: #ebf5df;
}
.btn-alt-success:hover, .btn-alt-success:focus, .btn-alt-success.focus {
  color: #5c852c;
  background-color: #d3e9b8;
  border-color: #d3e9b8;
  box-shadow: none;
}
.btn-alt-success.disabled, .btn-alt-success:disabled {
  background-color: #ebf5df;
  border-color: #ebf5df;
}
.btn-alt-success:not([disabled]):not(.disabled):active {
  color: #5c852c;
  background-color: #ebf5df;
  border-color: #ebf5df;
}
.btn-alt-success:not([disabled]):not(.disabled).active, .show > .btn-alt-success.dropdown-toggle {
  color: #5c852c;
  background-color: #d3e9b8;
  border-color: #d3e9b8;
}

.btn-alt-info {
  color: #13646e;
  background-color: #e3f4fc;
  border-color: #e3f4fc;
}
.btn-alt-info:hover, .btn-alt-info:focus, .btn-alt-info.focus {
  color: #13646e;
  background-color: #b5e2f7;
  border-color: #b5e2f7;
  box-shadow: none;
}
.btn-alt-info.disabled, .btn-alt-info:disabled {
  background-color: #e3f4fc;
  border-color: #e3f4fc;
}
.btn-alt-info:not([disabled]):not(.disabled):active {
  color: #13646e;
  background-color: #e3f4fc;
  border-color: #e3f4fc;
}
.btn-alt-info:not([disabled]):not(.disabled).active, .show > .btn-alt-info.dropdown-toggle {
  color: #13646e;
  background-color: #b5e2f7;
  border-color: #b5e2f7;
}

.btn-alt-warning {
  color: #a87e00;
  background-color: #fcf7e6;
  border-color: #fcf7e6;
}
.btn-alt-warning:hover, .btn-alt-warning:focus, .btn-alt-warning.focus {
  color: #a87e00;
  background-color: #f7e8b8;
  border-color: #f7e8b8;
  box-shadow: none;
}
.btn-alt-warning.disabled, .btn-alt-warning:disabled {
  background-color: #fcf7e6;
  border-color: #fcf7e6;
}
.btn-alt-warning:not([disabled]):not(.disabled):active {
  color: #a87e00;
  background-color: #fcf7e6;
  border-color: #fcf7e6;
}
.btn-alt-warning:not([disabled]):not(.disabled).active, .show > .btn-alt-warning.dropdown-toggle {
  color: #a87e00;
  background-color: #f7e8b8;
  border-color: #f7e8b8;
}

.btn-alt-danger {
  color: #af1310;
  background-color: #fae9e8;
  border-color: #fae9e8;
}
.btn-alt-danger:hover, .btn-alt-danger:focus, .btn-alt-danger.focus {
  color: #af1310;
  background-color: #f1c1be;
  border-color: #f1c1be;
  box-shadow: none;
}
.btn-alt-danger.disabled, .btn-alt-danger:disabled {
  background-color: #fae9e8;
  border-color: #fae9e8;
}
.btn-alt-danger:not([disabled]):not(.disabled):active {
  color: #af1310;
  background-color: #fae9e8;
  border-color: #fae9e8;
}
.btn-alt-danger:not([disabled]):not(.disabled).active, .show > .btn-alt-danger.dropdown-toggle {
  color: #af1310;
  background-color: #f1c1be;
  border-color: #f1c1be;
}

.btn-dual-secondary {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent;
}
.btn-dual-secondary:hover, .btn-dual-secondary:focus, .btn-dual-secondary.focus {
  color: #1f1f1f;
  background-color: #d1d7e1;
  border-color: #d1d7e1;
  box-shadow: none;
}
.btn-dual-secondary.disabled, .btn-dual-secondary:disabled {
  background-color: transparent;
  border-color: transparent;
}
.btn-dual-secondary:not([disabled]):not(.disabled):active {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent;
}
.btn-dual-secondary:not([disabled]):not(.disabled).active, .show > .btn-dual-secondary.dropdown-toggle {
  color: #1f1f1f;
  background-color: #d1d7e1;
  border-color: #d1d7e1;
}

.page-header-inverse #page-header .btn-dual-secondary,
.sidebar-inverse #sidebar .btn-dual-secondary {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-inverse #page-header .btn-dual-secondary:hover, .page-header-inverse #page-header .btn-dual-secondary:focus, .page-header-inverse #page-header .btn-dual-secondary.focus,
.sidebar-inverse #sidebar .btn-dual-secondary:hover,
.sidebar-inverse #sidebar .btn-dual-secondary:focus,
.sidebar-inverse #sidebar .btn-dual-secondary.focus {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
  box-shadow: none;
}
.page-header-inverse #page-header .btn-dual-secondary.disabled, .page-header-inverse #page-header .btn-dual-secondary:disabled,
.sidebar-inverse #sidebar .btn-dual-secondary.disabled,
.sidebar-inverse #sidebar .btn-dual-secondary:disabled {
  background-color: transparent;
  border-color: transparent;
}
.page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled):active,
.sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled):active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-inverse #page-header .btn-dual-secondary:not([disabled]):not(.disabled).active, .show > .page-header-inverse #page-header .btn-dual-secondary.dropdown-toggle,
.sidebar-inverse #sidebar .btn-dual-secondary:not([disabled]):not(.disabled).active,
.show > .sidebar-inverse #sidebar .btn-dual-secondary.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.fade {
  transition: opacity 0.15s ease-out;
}
.fade.fade-up {
  transition: opacity 0.15s ease-out, transform 0.3s ease-out;
  transform: translateY(100px);
}
.fade.fade-up.show {
  transform: translateY(0);
}
.fade.fade-right {
  transition: opacity 0.15s ease-out, transform 0.3s ease-out;
  transform: translateX(-100px);
}
.fade.fade-right.show {
  transform: translateX(0);
}
.fade.fade-left {
  transition: opacity 0.15s ease-out, transform 0.3s ease-out;
  transform: translateX(100px);
}
.fade.fade-left.show {
  transform: translateX(0);
}

.dropdown-menu {
  padding-right: 8px;
  padding-left: 8px;
}

.dropdown-item {
  margin-bottom: 5px;
  border-radius: 4px;
}

.dropdown-header {
  margin-bottom: 5px;
  text-transform: uppercase;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 3px;
}

.css-control {
  position: relative;
  display: inline-block;
  padding: 6px 0;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}
.css-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.css-control-input:focus ~ .css-control-indicator {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
}
.css-control-input ~ .css-control-indicator {
  position: relative;
  display: inline-block;
  margin-top: -3px;
  margin-right: 3px;
  vertical-align: middle;
}
.css-control-input ~ .css-control-indicator::after {
  position: absolute;
  content: "";
}
.css-control.css-control-sm {
  padding: 3px 0 4px;
  font-size: 1rem;
}
.css-control.css-control-lg {
  padding: 7px 0;
  font-size: 1.142857143rem;
}
.css-control.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.css-control + .css-control {
  margin-left: 10px;
}

.css-radio .css-control-input ~ .css-control-indicator {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.css-radio .css-control-input ~ .css-control-indicator::after {
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}
.css-radio .css-control-input:checked ~ .css-control-indicator::after {
  opacity: 1;
}
.css-radio:hover .css-control-input ~ .css-control-indicator {
  border-color: #e4e7ed;
}
.css-radio.css-control-sm .css-control-input ~ .css-control-indicator {
  width: 16px;
  height: 16px;
}
.css-radio.css-control-lg .css-control-input ~ .css-control-indicator {
  width: 26px;
  height: 26px;
}
.css-radio.css-control-primary .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #3f9ce8;
}
.css-radio.css-control-secondary .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #6c757d;
}
.css-radio.css-control-info .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #26c6da;
}
.css-radio.css-control-success .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #9ccc65;
}
.css-radio.css-control-warning .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #ffca28;
}
.css-radio.css-control-danger .css-control-input:checked ~ .css-control-indicator::after {
  background-color: #ef5350;
}

.css-checkbox .css-control-input ~ .css-control-indicator {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: background-color 0.15s;
}
.css-checkbox .css-control-input ~ .css-control-indicator::after {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  line-height: 18px;
  content: "";
  text-align: center;
}
.css-checkbox:hover .css-control-input ~ .css-control-indicator {
  border-color: #e4e7ed;
}
.css-checkbox.css-control-sm .css-control-input ~ .css-control-indicator {
  width: 16px;
  height: 16px;
}
.css-checkbox.css-control-sm .css-control-input ~ .css-control-indicator::after {
  font-size: 8px;
  line-height: 15px;
}
.css-checkbox.css-control-lg {
  padding: 6px 0 7px;
}
.css-checkbox.css-control-lg .css-control-input ~ .css-control-indicator {
  width: 28px;
  height: 28px;
}
.css-checkbox.css-control-lg .css-control-input ~ .css-control-indicator::after {
  font-size: 14px;
  line-height: 26px;
}
.css-checkbox.css-checkbox-rounded .css-control-input ~ .css-control-indicator {
  border-radius: 2px;
}
.css-checkbox.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: #3f9ce8;
  border-color: #1776c3;
}
.css-checkbox.css-control-secondary .css-control-input:checked ~ .css-control-indicator {
  background-color: #6c757d;
  border-color: #494f54;
}
.css-checkbox.css-control-info .css-control-input:checked ~ .css-control-indicator {
  background-color: #26c6da;
  border-color: #1a8b99;
}
.css-checkbox.css-control-success .css-control-input:checked ~ .css-control-indicator {
  background-color: #9ccc65;
  border-color: #76ac39;
}
.css-checkbox.css-control-warning .css-control-input:checked ~ .css-control-indicator {
  background-color: #ffca28;
  border-color: #dba500;
}
.css-checkbox.css-control-danger .css-control-input:checked ~ .css-control-indicator {
  background-color: #ef5350;
  border-color: #de1814;
}

.css-switch {
  padding: 3px 0;
}
.css-switch .css-control-input ~ .css-control-indicator {
  width: 51px;
  height: 30px;
  background-color: #eee;
  border-radius: 30px;
  transition: background-color 0.3s;
}
.css-switch .css-control-input ~ .css-control-indicator::after {
  top: 2px;
  bottom: 2px;
  left: 2px;
  width: 26px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.15s ease-out;
}
.css-switch .css-control-input:checked ~ .css-control-indicator {
  background-color: #ddd;
}
.css-switch .css-control-input:checked ~ .css-control-indicator::after {
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.3);
  transform: translateX(20px);
}
.css-switch.css-control-sm {
  padding: 3px 0;
}
.css-switch.css-control-sm .css-control-input ~ .css-control-indicator {
  width: 36px;
  height: 20px;
}
.css-switch.css-control-sm .css-control-input ~ .css-control-indicator::after {
  width: 16px;
}
.css-switch.css-control-sm .css-control-input:checked ~ .css-control-indicator::after {
  transform: translateX(15px);
}
.css-switch.css-control-lg {
  padding: 4px 0;
}
.css-switch.css-control-lg .css-control-input ~ .css-control-indicator {
  width: 65px;
  height: 34px;
}
.css-switch.css-control-lg .css-control-input ~ .css-control-indicator::after {
  width: 30px;
}
.css-switch.css-control-lg .css-control-input:checked ~ .css-control-indicator::after {
  transform: translateX(30px);
}
.css-switch.css-switch-square .css-control-input ~ .css-control-indicator, .css-switch.css-switch-square .css-control-input ~ .css-control-indicator::after {
  border-radius: 0;
}
.css-switch.css-control-primary .css-control-input:checked ~ .css-control-indicator {
  background-color: #3f9ce8;
}
.css-switch.css-control-secondary .css-control-input:checked ~ .css-control-indicator {
  background-color: #6c757d;
}
.css-switch.css-control-info .css-control-input:checked ~ .css-control-indicator {
  background-color: #26c6da;
}
.css-switch.css-control-success .css-control-input:checked ~ .css-control-indicator {
  background-color: #9ccc65;
}
.css-switch.css-control-warning .css-control-input:checked ~ .css-control-indicator {
  background-color: #ffca28;
}
.css-switch.css-control-danger .css-control-input:checked ~ .css-control-indicator {
  background-color: #ef5350;
}

.nav-link {
  color: #575757;
  font-weight: 600;
}
.nav-link:hover, .nav-link:focus {
  color: #3f9ce8;
}

.nav-tabs-block {
  background-color: #f6f7f9;
  border-bottom: none;
}
.nav-tabs-block .nav-item {
  margin-bottom: 0;
}
.nav-tabs-block .nav-link {
  padding-top: 12px;
  padding-bottom: 12px;
  border-color: transparent;
  border-radius: 0;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #3f9ce8;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #575757;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  background-color: transparent;
  border-bottom-color: #e4e7ed;
}
.nav-tabs-alt .nav-item {
  margin-bottom: -3px;
}
.nav-tabs-alt .nav-link {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #3f9ce8;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #3f9ce8;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -2px #3f9ce8;
}

.nav-users {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-users > li:last-child > a {
  border-bottom: none;
}
.nav-users a {
  position: relative;
  padding: 12px 8px 8px 71px;
  display: block;
  min-height: 62px;
  font-weight: 600;
  border-bottom: 1px solid #f0f2f5;
}
.nav-users a > img {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.nav-users a > i {
  position: absolute;
  left: 40px;
  top: 40px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
}
.nav-users a:hover {
  background-color: #f6f7f9;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "";
}

.page-link {
  font-weight: 600;
}
.page-link:focus {
  color: #171717;
  background-color: #e4e7ed;
  border-color: #e4e7ed;
}

.pagination-sm .page-link {
  font-size: 1rem;
  line-height: 16px;
}

.pagination-lg .page-link {
  font-size: 1.142857rem;
  line-height: 20px;
}

.badge-secondary {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.33);
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.33);
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  padding-top: 16px;
  padding-bottom: 16px;
}

.modal-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-width: 1px;
}

.modal-backdrop {
  background-color: #07233a;
}

.modal-dialog.modal-dialog-top {
  margin-top: 0 !important;
}

.modal.fade .modal-dialog {
  transition-duration: 0.35s;
}
.modal.fade .modal-dialog.modal-dialog-popin {
  transform: scale(1.2, 1.2);
}
.modal.fade .modal-dialog.modal-dialog-popout {
  transform: scale(0.8, 0.8);
}
.modal.fade .modal-dialog.modal-dialog-slideup {
  transform: translate(0, 25%);
}
.modal.fade .modal-dialog.modal-dialog-slideright {
  transform: translate(-25%, 0);
}
.modal.fade .modal-dialog.modal-dialog-slideleft {
  transform: translate(25%, 0);
}
.modal.fade .modal-dialog.modal-dialog-fromright {
  transform: translateX(25%) rotate(15deg) scale(0.8);
}
.modal.fade .modal-dialog.modal-dialog-fromleft {
  transform: translateX(-25%) rotate(-15deg) scale(0.8);
}
.modal.show .modal-dialog.modal-dialog-popin, .modal.show .modal-dialog.modal-dialog-popout {
  transform: scale(1, 1);
}
.modal.show .modal-dialog.modal-dialog-slideup, .modal.show .modal-dialog.modal-dialog-slideright, .modal.show .modal-dialog.modal-dialog-slideleft {
  transform: translate(0, 0);
}
.modal.show .modal-dialog.modal-dialog-fromright, .modal.show .modal-dialog.modal-dialog-fromleft {
  transform: translateX(0) rotate(0) scale(1);
}

.popover-header {
  padding-bottom: 1px;
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-bottom: none;
}

#page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
}

#page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(7, 35, 58, 0.4);
  background: linear-gradient(to right, rgba(7, 35, 58, 0), rgba(7, 35, 58, 0.6));
  z-index: 1033;
  opacity: 0;
  transition: opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715), transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
  transform: translateX(100%);
}
.sidebar-r #page-overlay {
  background: linear-gradient(to right, rgba(7, 35, 58, 0.6), rgba(7, 35, 58, 0));
  transform: translateX(-100%);
}
@media (min-width: 992px) {
  .side-overlay-o.enable-page-overlay #page-overlay {
    transform: translateX(0);
    opacity: 1;
  }
}

#main-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
}

.content {
  margin: 0 auto;
  padding: 12px 12px 1px;
  width: 100%;
  overflow-x: visible;
}
.content .pull-t {
  margin-top: -12px;
}
.content .pull-b {
  margin-bottom: -1px;
}
.content .pull-t-b {
  margin-top: -12px;
  margin-bottom: -1px;
}
.content .pull-r-l {
  margin-right: -12px;
  margin-left: -12px;
}
.content .pull-all {
  margin: -12px -12px -1px;
}
.content.content-full {
  padding-bottom: 12px;
}
.content.content-full .pull-b,
.content.content-full .pull-t-b,
.content.content-full .pull-all {
  margin-bottom: -12px;
}

.content p,
.content .push,
.content .block,
.content .items-push > div {
  margin-bottom: 12px;
}
.content .items-push-2x > div {
  margin-bottom: 24px;
}
.content .items-push-3x > div {
  margin-bottom: 36px;
}
@media (min-width: 768px) {
  .content {
    margin: 0 auto;
    padding: 24px 24px 1px;
    width: 100%;
    overflow-x: visible;
  }
  .content .pull-t {
    margin-top: -24px;
  }
  .content .pull-b {
    margin-bottom: -1px;
  }
  .content .pull-t-b {
    margin-top: -24px;
    margin-bottom: -1px;
  }
  .content .pull-r-l {
    margin-right: -24px;
    margin-left: -24px;
  }
  .content .pull-all {
    margin: -24px -24px -1px;
  }
  .content.content-full {
    padding-bottom: 24px;
  }
  .content.content-full .pull-b,
.content.content-full .pull-t-b,
.content.content-full .pull-all {
    margin-bottom: -24px;
  }

  .content p,
.content .push,
.content .block,
.content .items-push > div {
    margin-bottom: 24px;
  }
  .content .items-push-2x > div {
    margin-bottom: 48px;
  }
  .content .items-push-3x > div {
    margin-bottom: 72px;
  }
}
@media (min-width: 1200px) {
  .content.content-narrow {
    width: 92%;
  }
}
.content.content-boxed {
  max-width: 1200px;
}

.content-side {
  margin: 0 auto;
  padding: 18px 18px 1px;
  width: 100%;
  overflow-x: hidden;
}
.content-side .pull-t {
  margin-top: -18px;
}
.content-side .pull-b {
  margin-bottom: -1px;
}
.content-side .pull-t-b {
  margin-top: -18px;
  margin-bottom: -1px;
}
.content-side .pull-r-l {
  margin-right: -18px;
  margin-left: -18px;
}
.content-side .pull-all {
  margin: -18px -18px -1px;
}
.content-side.content-side-full {
  padding-bottom: 18px;
}
.content-side.content-side-full .pull-b,
.content-side.content-side-full .pull-t-b,
.content-side.content-side-full .pull-all {
  margin-bottom: -18px;
}

.content-side p,
.content-side .push,
.content-side .block,
.content-side .items-push > div {
  margin-bottom: 18px;
}
.content-side .items-push-2x > div {
  margin-bottom: 36px;
}
.content-side .items-push-3x > div {
  margin-bottom: 54px;
}
.content-side-user {
  height: 136px;
  background-color: #f6f7f9;
  overflow: hidden;
}

.content.content-top {
  padding-top: 80px;
}
@media (min-width: 992px) {
  .content.content-top {
    padding-top: 92px;
  }
}

.section-top {
  padding-top: 68px;
}

#page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
}

.overlay-header {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform: translateY(-100%);
  will-change: opacity, transform;
}
.overlay-header.show {
  opacity: 1;
  transform: translateY(0);
}

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 17px 18px;
  height: 68px;
}
.content-header.content-header-fullrow {
  flex-direction: column;
  align-items: stretch;
}
.content-header-section {
  flex: 0 0 auto;
}
.content-header-section.content-header-section-wrap {
  flex: 1 1 auto;
}
.content-header-item {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
}
.content-header-item-align {
  vertical-align: middle;
}
.content-header-logo {
  height: 34px;
}
#page-header > .content-header {
  padding-left: 12px;
  padding-right: 12px;
}
@media (min-width: 768px) {
  #page-header > .content-header {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.sidebar-mini-visible,
.sidebar-mini-visible-b {
  display: none;
}

.sidebar-mini-show {
  opacity: 0;
}

.sidebar-mini-show,
.sidebar-mini-hide {
  transition: opacity 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
}

.sidebar-mini-show.sidebar-mini-notrans,
.sidebar-mini-hide.sidebar-mini-notrans {
  transition: none;
}

#sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1032;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: visible;
  transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #sidebar {
  transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.sidebar-r #sidebar {
  right: 0;
  left: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
}
#sidebar .js-sidebar-scroll {
  height: calc(100vh - 68px);
  overflow-y: auto;
}
#sidebar .js-sidebar-scroll.full-height {
  height: 100%;
}
@media (max-width: 991.98px) {
  .sidebar-o-xs #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
}
@media (min-width: 992px) {
  #sidebar {
    width: 230px;
  }
  .sidebar-o #sidebar {
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .sidebar-o.sidebar-mini #sidebar {
    overflow-x: hidden;
    transform: translateX(-176px) translateY(0) translateZ(0);
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  .sidebar-r.sidebar-o.sidebar-mini #sidebar {
    transform: translateX(176px) translateY(0) translateZ(0);
  }
  .sidebar-mini.sidebar-o #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar .content-header {
    width: 230px;
    transform: translateX(176px) translateY(0) translateZ(0);
    transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
    will-change: transform;
  }
  .sidebar-mini.sidebar-o #sidebar:hover, .sidebar-mini.sidebar-o #sidebar:hover .content-side, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side, .sidebar-mini.sidebar-o #sidebar:hover .content-header, .sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(0);
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hide {
    opacity: 0;
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-show {
    opacity: 1;
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden,
.sidebar-o.sidebar-mini #sidebar .sidebar-mini-hidden-b {
    display: none;
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-visible {
    display: inline-block;
  }
  .sidebar-o.sidebar-mini #sidebar .sidebar-mini-visible-b {
    display: block;
  }
  .sidebar-o.sidebar-mini #sidebar .nav-main > li.open > ul {
    display: none;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hide {
    opacity: 1;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-show {
    opacity: 0;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hidden {
    display: inline-block;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-hidden-b {
    display: block;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-visible,
.sidebar-o.sidebar-mini #sidebar:hover .sidebar-mini-visible-b {
    display: none;
    opacity: 0;
  }
  .sidebar-o.sidebar-mini #sidebar:hover .nav-main > li.open > ul {
    display: block;
  }
}

#side-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1034;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  transform: translateX(100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;
}
.side-trans-enabled #side-overlay {
  transition: transform 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.sidebar-r #side-overlay {
  right: auto;
  left: 0;
  transform: translateX(-100%) translateY(0) translateZ(0);
}
.side-overlay-o #side-overlay {
  transform: translateX(0) translateY(0) translateZ(0);
}
@media (min-width: 992px) {
  #side-overlay {
    width: 320px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
    transform: translateX(110%) translateY(0) translateZ(0);
  }
  .sidebar-r #side-overlay {
    transform: translateX(-110%) translateY(0) translateZ(0);
  }
  .side-overlay-hover #side-overlay {
    transform: translateX(302px) translateY(0) translateZ(0);
  }
  .sidebar-r.side-overlay-hover #side-overlay {
    transform: translateX(-302px) translateY(0) translateZ(0);
  }
  .side-overlay-hover #side-overlay:hover, .side-overlay-o #side-overlay, .side-overlay-o.side-overlay-hover #side-overlay {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    transform: translateX(0) translateY(0) translateZ(0);
  }
}

#page-container > #page-header .content-header,
#page-container > #page-header .content,
#page-container > #main-container .content,
#page-container > #page-footer .content {
  max-width: 1920px;
}
@media (min-width: 1200px) {
  #page-container.main-content-narrow > #page-header .content-header,
#page-container.main-content-narrow > #page-header .content,
#page-container.main-content-narrow > #main-container .content,
#page-container.main-content-narrow > #page-footer .content {
    width: 92%;
  }
}
#page-container.main-content-boxed > #page-header .content-header,
#page-container.main-content-boxed > #page-header .content,
#page-container.main-content-boxed > #main-container .content,
#page-container.main-content-boxed > #page-footer .content {
  max-width: 1200px;
}
@media (min-width: 992px) {
  #page-container.page-header-modern #page-header {
    background-color: #f0f2f5;
    box-shadow: none !important;
  }
  #page-container.page-header-modern #page-header > .content-header {
    padding-top: 32px;
    padding-bottom: 2px;
  }
}
#page-container.page-header-inverse #page-header {
  color: #cbd2dd;
  background-color: #343a40;
}
#page-container.page-header-glass #page-header {
  position: absolute;
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed #page-header {
  transition: background-color 0.2s linear;
  will-change: background-color;
  box-shadow: none;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-inverse #page-header {
  background-color: #343a40;
  box-shadow: none;
}
#page-container.page-header-glass #main-container, #page-container.page-header-glass.page-header-fixed #main-container {
  padding-top: 0;
}
#page-container.page-header-fixed #page-header {
  position: fixed;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02);
}
#page-container.page-header-fixed #page-header > .content-header {
  padding-top: 17px;
  padding-bottom: 17px;
}
#page-container.page-header-fixed.page-header-inverse #page-header {
  box-shadow: none;
}
#page-container.page-header-fixed #main-container {
  padding-top: 68px;
}
#page-container.page-header-fixed #page-header, #page-container.page-header-glass #page-header {
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto;
}
@media (min-width: 992px) {
  #page-container.page-header-fixed.sidebar-o #page-header, #page-container.page-header-glass.sidebar-o #page-header {
    left: 230px;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o #page-header, #page-container.page-header-glass.sidebar-r.sidebar-o #page-header {
    right: 230px;
    left: 0;
  }
  #page-container.page-header-fixed.sidebar-o.sidebar-mini #page-header, #page-container.page-header-glass.sidebar-o.sidebar-mini #page-header {
    left: 54px;
  }
  #page-container.page-header-fixed.sidebar-r.sidebar-o.sidebar-mini #page-header, #page-container.page-header-glass.sidebar-r.sidebar-o.sidebar-mini #page-header {
    right: 54px;
    left: 0;
  }
}
@media (min-width: 992px) {
  #page-container.page-header-modern #sidebar {
    box-shadow: 5px 0 10px #e4e7ed;
  }
  #page-container.page-header-modern.sidebar-r #sidebar {
    box-shadow: -5px 0 10px #e4e7ed;
  }
}
#page-container.sidebar-inverse #sidebar {
  color: #e4e7ed;
  background-color: #343a40;
  box-shadow: none;
}
#page-container.sidebar-inverse #sidebar .content-side-user {
  background-color: #2d3238;
}
#page-container.side-scroll #sidebar .js-sidebar-scroll,
#page-container.side-scroll #side-overlay {
  overflow-y: visible;
}
@media (min-width: 992px) {
  #page-container.side-scroll #sidebar .content-header,
#page-container.side-scroll #sidebar .content-side {
    width: 230px !important;
  }
  #page-container.side-scroll #side-overlay .content-header,
#page-container.side-scroll #side-overlay .content-side {
    width: 320px !important;
  }
}
@media (min-width: 992px) {
  #page-container.sidebar-o {
    padding-left: 230px;
  }
  #page-container.sidebar-r.sidebar-o {
    padding-right: 230px;
    padding-left: 0;
  }
  #page-container.sidebar-mini.sidebar-o {
    padding-left: 54px;
  }
  #page-container.sidebar-mini.sidebar-o.sidebar-r {
    padding-right: 54px;
    padding-left: 0;
  }
}

.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;
}
.hero.hero-sm {
  min-height: 300px;
}
.hero.hero-lg {
  min-height: 800px;
}
.hero-inner {
  flex: 0 0 auto;
  width: 100%;
}

.hero-static {
  min-height: 100vh;
}

.hero-promo {
  position: relative;
  overflow: hidden;
}
.hero-promo::before {
  position: absolute;
  right: -500px;
  bottom: -500px;
  left: 0;
  height: 640px;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  transform: skewY(-20deg);
  z-index: 0;
}

.hero-bubbles {
  position: relative;
  overflow: hidden;
}
.hero-bubbles .hero-bubble {
  position: absolute;
  display: inline-block;
  border-radius: 100%;
  opacity: 0.2;
  z-index: 0;
}
.hero-bubbles .hero-bubble:nth-child(odd) {
  animation: hero-bubble 15s infinite ease-in;
}
.hero-bubbles .hero-bubble:nth-child(even) {
  animation: hero-bubble 15s infinite ease-in reverse;
}

@keyframes hero-bubble {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.5) translateY(1rem);
  }
  50% {
    opacity: 0.5;
  }
  66% {
    transform: scale(0.5) translateY(0);
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}
.block {
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0 1px 1px #e4e7ed;
}
.block .block, .content-side .block {
  box-shadow: none;
}

.block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  transition: opacity 0.2s ease-out;
}
.block-header.block-header-rtl {
  flex-direction: row-reverse;
}
.block-header.block-header-rtl .block-title {
  text-align: right;
}
.block-header.block-header-rtl .block-options {
  padding-right: 10px;
  padding-left: 0;
}
.block-header-default {
  background-color: #f6f7f9;
}

.block-title {
  flex: 1 1 auto;
  min-height: 28px;
  margin: 0;
  font-size: 1.142857143rem;
  font-weight: 400;
  line-height: 28px;
}
.block-title small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  color: #646464;
}

.block-content {
  transition: opacity 0.2s ease-out;
  margin: 0 auto;
  padding: 20px 20px 1px;
  width: 100%;
  overflow-x: visible;
}
.block-content .pull-t {
  margin-top: -20px;
}
.block-content .pull-b {
  margin-bottom: -1px;
}
.block-content .pull-t-b {
  margin-top: -20px;
  margin-bottom: -1px;
}
.block-content .pull-r-l {
  margin-right: -20px;
  margin-left: -20px;
}
.block-content .pull-all {
  margin: -20px -20px -1px;
}
.block-content.block-content-full {
  padding-bottom: 20px;
}
.block-content.block-content-full .pull-b,
.block-content.block-content-full .pull-t-b,
.block-content.block-content-full .pull-all {
  margin-bottom: -20px;
}

.block-content p,
.block-content .push,
.block-content .block,
.block-content .items-push > div {
  margin-bottom: 20px;
}
.block-content .items-push-2x > div {
  margin-bottom: 40px;
}
.block-content .items-push-3x > div {
  margin-bottom: 60px;
}
.block-content.block-content-sm {
  padding-top: 10px;
}
.block-content.block-content-sm .pull-t,
.block-content.block-content-sm .pull-t-b,
.block-content.block-content-sm .pull-all {
  margin-top: -10px;
}
.block-content.block-content-sm.block-content-full {
  padding-bottom: 10px;
}
.block-content.block-content-sm.block-content-full .pull-b,
.block-content.block-content-sm.block-content-full .pull-t-b,
.block-content.block-content-sm.block-content-full .pull-all {
  margin-bottom: -10px;
}

.block-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.block-table .block-row {
  display: table-row;
}
.block-table .block-cell {
  display: table-cell;
  padding: 20px;
  vertical-align: middle;
}

.block.block-bordered {
  border: 1px solid #e4e7ed;
  box-shadow: none;
}
.block.block-rounded {
  border-radius: 4px;
}
.block.block-rounded > .block-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.block.block-rounded > .block-header:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.block.block-rounded > .block-content:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.block.block-rounded > .block-content:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.block.block-themed > .block-header {
  border-bottom: none;
  color: #fff;
  background-color: #3f9ce8;
}
.block.block-themed > .block-header > .block-title {
  color: rgba(255, 255, 255, 0.9);
}
.block.block-themed > .block-header > .block-title small {
  color: rgba(255, 255, 255, 0.7);
}
.block.block-transparent {
  background-color: transparent;
  box-shadow: none;
}
.block.block-shadow {
  box-shadow: 0 15px 30px rgba(33, 37, 41, 0.05);
}

.block.block-mode-hidden.block-bordered > .block-header {
  border-bottom: none;
}
.block.block-mode-hidden > .block-content {
  display: none;
}
.block.block-mode-loading {
  position: relative;
  overflow: hidden;
}
.block.block-mode-loading > .block-header,
.block.block-mode-loading > .block-content,
.block.block-mode-loading > .nav-tabs {
  opacity: 0.05;
}
.block.block-mode-loading::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1;
  content: " ";
}
.block.block-mode-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #343a40;
  font-family: Simple-Line-Icons;
  font-size: 18px;
  text-align: center;
  z-index: 2;
  content: "";
  animation: fa-spin 1.75s infinite linear;
  border-radius: 25px;
  box-shadow: 0 0 15px 15px rgba(52, 58, 64, 0.05);
}
.block.block-mode-loading.block-mode-hidden::after {
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  box-shadow: 0 0 8px 8px rgba(52, 58, 64, 0.05);
}
.block.block-mode-loading.block-mode-loading-inverse::after {
  background-color: #343a40;
  color: #fff;
  box-shadow: 0 0 15px 15px rgba(255, 255, 255, 0.5);
}
.block.block-mode-loading.block-mode-loading-location::after {
  content: "";
}
.block.block-mode-loading.block-mode-loading-energy::after {
  content: "";
}
.block.block-mode-loading.block-mode-loading-refresh::after {
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "";
}
.block.block-mode-loading.block-mode-loading-sun::after {
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  content: "";
}
.block.block-mode-loading.block-mode-loading-repeat::after {
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-weight: 900;
  content: "";
}
.block.block-mode-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1035;
  margin-bottom: 0;
  overflow-y: auto;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
}
.block.block-mode-fullscreen.block-bordered {
  border: none;
}
.block.block-mode-fullscreen.block-rounded {
  border-radius: 0;
}
.block.block-mode-fullscreen.block-transparent {
  background-color: #fff;
}
.block.block-mode-pinned {
  position: fixed;
  right: 10px;
  bottom: 0;
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 300px;
  z-index: 1031;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.block.block-mode-pinned > .block-content {
  max-height: 250px;
  overflow-y: auto;
}

a.block {
  display: block;
  color: #575757;
  font-weight: normal;
  transition: all 0.12s ease-out;
}
a.block:hover {
  color: #575757;
  opacity: 0.65;
}
a.block:active {
  opacity: 1;
}
a.block.block-link-rotate:hover {
  transform: rotate(1deg);
  opacity: 1;
}
a.block.block-link-rotate:active {
  transform: rotate(0deg);
}
a.block.block-link-pop:hover {
  box-shadow: 0 2px 30px #d8dde5;
  transform: translateY(-3px);
  opacity: 1;
}
a.block.block-link-pop:active {
  box-shadow: 0 2px 10px #edeff3;
  transform: translateY(0);
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 36px #d8dde5;
  opacity: 1;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 10px #e4e7ed;
}

.block.block-fx-rotate {
  transform: rotate(1deg);
  opacity: 1;
}
.block.block-fx-pop {
  box-shadow: 0 2px 30px #d8dde5;
  transform: translateY(-3px);
  opacity: 1;
}
.block.block-fx-shadow {
  box-shadow: 0 0 36px #d8dde5;
  opacity: 1;
}

.block-options {
  flex: 0 0 auto;
  padding-left: 10px;
  min-height: 28px;
}
.block-options .block-options-item {
  padding: 6px 5px;
  line-height: 1.2;
}
.block.block-themed > .block-header .block-options .block-options-item {
  color: #fff;
}
.block-options .block-options-item,
.block-options .dropdown,
.block-options .dropup,
.block-options .dropright,
.block-options .dropleft {
  display: inline-block;
}

.block-sticky-options {
  position: relative;
}
.block-sticky-options .block-options {
  position: absolute;
  top: 14px;
  right: 20px;
}
.block-sticky-options .block-options.block-options-left {
  right: auto;
  left: 20px;
  padding-right: 10px;
  padding-left: 0;
}

.btn-block-option {
  display: inline-block;
  padding: 6px 5px;
  line-height: 1.2;
  color: #6c757d;
  background: none;
  border: none;
  cursor: pointer;
}
.btn-block-option .si {
  position: relative;
  top: 1px;
}
.btn-block-option:hover {
  text-decoration: none;
  color: #212529;
}
a.btn-block-option:focus,
.active > a.btn-block-option,
.show > button.btn-block-option {
  text-decoration: none;
  color: #212529;
}

.btn-block-option:focus {
  outline: none !important;
  color: #000;
}
.btn-block-option:active {
  color: #6c757d;
}

.block.block-themed > .block-header > .block-options .btn-block-option,
.block.block-themed > .block-content > .block-options .btn-block-option {
  color: #fff;
  opacity: 0.7;
}
.block.block-themed > .block-header > .block-options .btn-block-option:hover,
.block.block-themed > .block-content > .block-options .btn-block-option:hover {
  color: #fff;
  opacity: 1;
}
.block.block-themed > .block-header > .block-options .btn-block-option:focus,
.block.block-themed > .block-content > .block-options .btn-block-option:focus {
  color: #fff;
  opacity: 1;
}
.block.block-themed > .block-header > .block-options .btn-block-option:active,
.block.block-themed > .block-content > .block-options .btn-block-option:active {
  color: #fff;
  opacity: 0.6;
}
.block.block-themed > .block-header > .block-options a.btn-block-option:focus,
.block.block-themed > .block-header > .block-options .active > a.btn-block-option,
.block.block-themed > .block-header > .block-options .show > button.btn-block-option,
.block.block-themed > .block-content > .block-options a.btn-block-option:focus,
.block.block-themed > .block-content > .block-options .active > a.btn-block-option,
.block.block-themed > .block-content > .block-options .show > button.btn-block-option {
  color: #fff;
  opacity: 1;
}

#page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3f9ce8;
  z-index: 999998;
  transition: transform 0.35s ease-out;
  transform: translateY(-100%);
  will-change: transform;
}
#page-loader.show {
  transform: translateY(0);
}
#page-loader::after, #page-loader::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border-radius: 100%;
  content: "";
  z-index: 999999;
}
#page-loader::after {
  margin-top: -100px;
  margin-left: -100px;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 3px solid rgba(255, 255, 255, 0.75);
  animation: page-loader-inner 1.5s infinite ease-out;
}
#page-loader::before {
  margin-top: -30px;
  margin-left: -30px;
  width: 60px;
  height: 60px;
  background-color: white;
  animation: page-loader-outer 1.5s infinite ease-in;
}

@keyframes page-loader-inner {
  0%, 40% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes page-loader-outer {
  0% {
    transform: scale(1);
  }
  40%, 100% {
    transform: scale(0);
    opacity: 0;
  }
}
.nav-main {
  margin: 0 -18px;
  padding: 0;
  list-style: none;
}
.nav-main .nav-main-heading {
  padding: 30px 18px 6px 18px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.5;
}
.nav-main a {
  position: relative;
  display: block;
  padding: 9px 18px 9px 40px;
  font-weight: 600;
  color: rgba(87, 87, 87, 0.9);
}
.sidebar-inverse #sidebar .nav-main a {
  color: rgba(228, 231, 237, 0.75);
}

.nav-main a > i {
  position: absolute;
  top: 49%;
  right: auto;
  left: 18px;
  transform: translateX(0) translateY(-50%);
  font-size: 14px;
  color: rgba(52, 58, 64, 0.75);
}
.sidebar-inverse #sidebar .nav-main a > i {
  color: rgba(255, 255, 255, 0.3);
}

.nav-main a:hover, .nav-main a:focus, .nav-main a.active {
  color: #000;
}
.sidebar-inverse #sidebar .nav-main a:hover, .sidebar-inverse #sidebar .nav-main a:focus, .sidebar-inverse #sidebar .nav-main a.active {
  color: #fff;
}

.nav-main a:hover > i, .nav-main a:focus > i, .nav-main a.active > i {
  color: #3f9ce8;
}
.sidebar-inverse #sidebar .nav-main a:hover > i, .sidebar-inverse #sidebar .nav-main a:focus > i, .sidebar-inverse #sidebar .nav-main a.active > i {
  color: #3f9ce8;
}

.nav-main a.nav-submenu {
  padding-right: 35px;
}
.nav-main a.nav-submenu::before, .nav-main a.nav-submenu::after {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -11px;
  width: 20px;
  height: 20px;
  display: block;
  line-height: 20px;
  text-align: center;
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  font-size: 14px;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.nav-main a.nav-submenu::before {
  content: "";
  opacity: 0.4;
}
.nav-main a.nav-submenu::after {
  content: "";
  opacity: 0;
  transform: rotate(90deg);
}
.nav-main ul {
  margin: 0;
  padding: 0 0 0 40px;
  height: 0;
  list-style: none;
  overflow: hidden;
}
.nav-main ul > li {
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(-15px);
}
.nav-main ul .nav-main-heading {
  padding-left: 0;
  padding-right: 0;
}
.nav-main ul a {
  padding: 6px 8px 6px 0;
  font-weight: 400;
  color: #979797;
}
.nav-main ul a:hover, .nav-main ul a:focus {
  background-color: transparent;
}
.nav-main ul a > i {
  margin-right: 10px;
}
.nav-main ul ul {
  padding-left: 12px;
}
.nav-main li.open > a.nav-submenu {
  color: #000;
}
.sidebar-inverse #sidebar .nav-main li.open > a.nav-submenu {
  color: #fff;
}

.nav-main li.open > a.nav-submenu > i {
  color: #3f9ce8;
}
.sidebar-inverse #sidebar .nav-main li.open > a.nav-submenu > i {
  color: #3f9ce8;
}

.nav-main li.open > a.nav-submenu::before {
  opacity: 0;
  transform: rotate(-90deg);
}
.nav-main li.open > a.nav-submenu::after {
  opacity: 0.4;
  transform: rotate(0);
}
.nav-main li.open > ul {
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f6f7f9;
}
.sidebar-inverse #sidebar .nav-main li.open > ul {
  background-color: #2d3238;
}

.nav-main li.open > ul > li {
  opacity: 1;
  transform: translateX(0);
}

.nav-main-header {
  display: none;
}
@media (min-width: 992px) {
  .nav-main-header {
    margin: 0 -3px;
    padding: 0;
    display: inline-block;
    list-style: none;
    vertical-align: middle;
  }
  .nav-main-header li {
    position: relative;
    display: inline-block;
    margin: 0 3px;
  }
  .nav-main-header a {
    position: relative;
    display: inline-block;
    padding: 9px 14px;
    font-weight: 600;
    line-height: 16px;
    border-radius: 3px;
  }
  .nav-main-header a > i {
    margin-right: 8px;
  }
  .nav-main-header a > i.si {
    position: relative;
    top: 1px;
  }
  .nav-main-header a.nav-submenu {
    padding-right: 28px;
  }
  .nav-main-header a.nav-submenu::before {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    display: block;
    text-align: center;
    line-height: 20px;
    font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  }
  .nav-main-header a.nav-submenu::before {
    content: "";
    opacity: 0.5;
  }
  .nav-main-header ul {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 15px 0;
    display: none;
    z-index: 1032;
  }
  .nav-main-header ul li {
    display: block;
    margin: 0;
  }
  .nav-main-header ul a {
    display: block;
    border-radius: 0;
    min-width: 160px;
  }
  .nav-main-header ul a.nav-submenu::before {
    content: "";
  }
  .nav-main-header ul ul {
    top: -15px;
    left: 100%;
  }
  .nav-main-header li:hover > a.nav-submenu {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .nav-main-header li:hover > ul {
    display: block;
  }
  .nav-main-header > li:last-child ul {
    right: 0;
    left: auto;
  }
  .nav-main-header > li:last-child ul a.nav-submenu::before {
    content: "";
  }
  .nav-main-header > li:last-child ul ul {
    right: 100%;
    left: auto;
  }
  .nav-main-header a {
    color: #717171;
  }
  .nav-main-header ul a {
    color: #8f8f8f;
  }
  .nav-main-header a:hover,
.nav-main-header a:focus,
.nav-main-header a.active,
.nav-main-header li.open > a.nav-submenu,
.nav-main-header li:hover > a.nav-submenu {
    color: #000;
    background-color: #e4e7ed;
  }
  .nav-main-header ul,
.nav-main-header > li:hover > a.nav-submenu {
    background-color: #dbdfe7;
  }
  .page-header-inverse #page-header .nav-main-header a, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a {
    color: #c8d0da;
  }
  .page-header-inverse #page-header .nav-main-header ul a, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul a {
    color: #adb8c8;
  }
  .page-header-inverse #page-header .nav-main-header a:hover,
.page-header-inverse #page-header .nav-main-header a:focus,
.page-header-inverse #page-header .nav-main-header a.active,
.page-header-inverse #page-header .nav-main-header li.open > a.nav-submenu,
.page-header-inverse #page-header .nav-main-header li:hover > a.nav-submenu, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:hover,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a:focus,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header a.active,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li.open > a.nav-submenu,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header li:hover > a.nav-submenu {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .page-header-inverse #page-header .nav-main-header ul,
.page-header-inverse #page-header .nav-main-header > li:hover > a.nav-submenu, .page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header ul,
.page-header-inverse.page-header-glass.page-header-scroll #page-header .nav-main-header > li:hover > a.nav-submenu {
    background-color: #343a40;
  }

  .page-header-inverse.page-header-glass #page-header .nav-main-header ul,
.page-header-inverse.page-header-glass #page-header .nav-main-header > li:hover > a.nav-submenu {
    background-color: #292d32;
  }

  .nav-main-header .nav-main-heading {
    display: none;
  }
  .nav-main-header.nav-main-header-no-icons a > i {
    display: none;
  }
}

.form-material {
  position: relative;
  padding-top: 26px;
}
.form-material > label {
  position: absolute;
  top: 6px;
  left: 0;
  margin-bottom: 0;
}
.form-material.floating > label {
  font-size: 1.142857143rem;
  font-weight: 400;
  cursor: text;
  z-index: 2;
  transition: all 0.2s ease-out;
  transform: translateY(25px);
}
.form-material.form-material-sm.floating > label {
  font-size: 1rem;
  transform: translateY(22px);
}
.form-material.form-material-lg.floating > label {
  font-size: 1.285714286rem;
  transform: translateY(27px);
}
.form-material > label, .form-material > .form-control:focus + label, .form-material.floating.open > label {
  font-size: 1rem;
  font-weight: 600;
  cursor: default;
  transform: translateY(0);
}
.form-material.floating > .form-control:-webkit-autofill + label {
  font-size: 1rem;
  font-weight: 600;
  cursor: default;
  transform: translateY(0);
}
.form-material.floating > .form-control[disabled] + label, .form-material.floating > .form-control[readonly] + label, fieldset[disabled] .form-material.floating > label {
  cursor: not-allowed;
}
.form-material > .form-control {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 1px 0 #d4dae3;
  transition: box-shadow 0.3s ease-out;
}
.form-material > .form-control ~ .input-group-append {
  box-shadow: 0 1px 0 #d4dae3;
  transition: box-shadow 0.3s ease-out;
}
.form-material > .form-control:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 2px 0 #97a5ba;
}
.form-material > .form-control:focus + label {
  color: #575757;
}
.form-material > .form-control:focus ~ .input-group-append {
  box-shadow: 0 2px 0 #97a5ba;
}
.form-material > select.form-control {
  border-radius: 1px;
}
.form-material .form-control[disabled],
.form-material .form-control[readonly],
.form-material fieldset[disabled] .form-control {
  background-color: #fff;
  border-bottom: 1px dashed #ccc;
  box-shadow: none;
}
.form-material.input-group .input-group-text {
  border: none;
  background-color: transparent;
}
.form-material.form-material-primary > .form-control:focus {
  box-shadow: 0 2px #3f9ce8;
}
.form-material.form-material-primary > .form-control:focus + label {
  color: #3f9ce8;
}
.form-material.form-material-primary > .form-control:focus ~ .input-group-append {
  color: #3f9ce8;
  box-shadow: 0 2px #3f9ce8;
}
.form-material.form-material-success > .form-control:focus {
  box-shadow: 0 2px #9ccc65;
}
.form-material.form-material-success > .form-control:focus + label {
  color: #9ccc65;
}
.form-material.form-material-success > .form-control:focus ~ .input-group-append {
  color: #9ccc65;
  box-shadow: 0 2px #9ccc65;
}
.form-material.form-material-info > .form-control:focus {
  box-shadow: 0 2px #26c6da;
}
.form-material.form-material-info > .form-control:focus + label {
  color: #26c6da;
}
.form-material.form-material-info > .form-control:focus ~ .input-group-append {
  color: #26c6da;
  box-shadow: 0 2px #26c6da;
}
.form-material.form-material-warning > .form-control:focus {
  box-shadow: 0 2px #ffca28;
}
.form-material.form-material-warning > .form-control:focus + label {
  color: #ffca28;
}
.form-material.form-material-warning > .form-control:focus ~ .input-group-append {
  color: #ffca28;
  box-shadow: 0 2px #ffca28;
}
.form-material.form-material-danger > .form-control:focus {
  box-shadow: 0 2px #ef5350;
}
.form-material.form-material-danger > .form-control:focus + label {
  color: #ef5350;
}
.form-material.form-material-danger > .form-control:focus ~ .input-group-append {
  color: #ef5350;
  box-shadow: 0 2px #ef5350;
}

.is-valid .form-material > .form-control {
  box-shadow: 0 1px 0 #9ccc65;
}
.is-valid .form-material > .form-control ~ .input-group-append {
  color: #9ccc65;
  box-shadow: 0 1px 0 #9ccc65;
}
.is-valid .form-material > .form-control:focus {
  box-shadow: 0 2px #9ccc65;
}
.is-valid .form-material > .form-control:focus + label {
  color: #9ccc65;
}
.is-valid .form-material > .form-control:focus ~ .input-group-append {
  color: #9ccc65;
  box-shadow: 0 2px #9ccc65;
}
.is-valid .form-material ~ .invalid-feedback,
.is-valid .form-material ~ .invalid-tooltip {
  display: block;
}
.is-valid .form-material label {
  color: #9ccc65;
}

.is-invalid .form-material > .form-control {
  box-shadow: 0 1px 0 #ef5350;
}
.is-invalid .form-material > .form-control ~ .input-group-append {
  color: #ef5350;
  box-shadow: 0 1px 0 #ef5350;
}
.is-invalid .form-material > .form-control:focus {
  box-shadow: 0 2px #ef5350;
}
.is-invalid .form-material > .form-control:focus + label {
  color: #ef5350;
}
.is-invalid .form-material > .form-control:focus ~ .input-group-append {
  color: #ef5350;
  box-shadow: 0 2px #ef5350;
}
.is-invalid .form-material ~ .invalid-feedback,
.is-invalid .form-material ~ .invalid-tooltip {
  display: block;
}
.is-invalid .form-material label {
  color: #ef5350;
}

.img-avatar {
  display: inline-block !important;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.img-avatar.img-avatar16 {
  width: 16px;
  height: 16px;
}
.img-avatar.img-avatar20 {
  width: 20px;
  height: 20px;
}
.img-avatar.img-avatar32 {
  width: 32px;
  height: 32px;
}
.img-avatar.img-avatar48 {
  width: 48px;
  height: 48px;
}
.img-avatar.img-avatar96 {
  width: 96px;
  height: 96px;
}
.img-avatar.img-avatar128 {
  width: 128px;
  height: 128px;
}
.img-avatar-thumb {
  margin: 5px;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
}
.img-avatar.img-avatar-rounded {
  border-radius: 3px;
}
.img-avatar.img-avatar-square {
  border-radius: 0;
}

.img-thumb {
  padding: 6px;
  background-color: #fff;
  border-radius: 3px;
}

.img-link {
  display: inline-block;
  transition: transform 0.25s ease-out, opacity 0.25s ease-out;
}
.img-link:hover {
  transform: scale(1.04);
  opacity: 0.75;
}
.img-link:active {
  transform: scale(1);
  opacity: 1;
}
.img-link.img-link-simple:hover {
  transform: scale(1);
}

.img-link-zoom-in {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.img-status {
  position: relative;
  display: inline-block;
}
.img-status-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  border-radius: 5px;
  z-index: 1;
}

.img-fluid.img-fluid-100,
.img-fluid-100 .img-fluid {
  width: 100%;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list > li {
  position: relative;
}
.list.list-simple > li, .list-li-push > li {
  margin-bottom: 20px;
}
.list.list-simple-mini > li, .list-li-push-sm > li {
  margin-bottom: 10px;
}

.list-timeline {
  position: relative;
  padding-top: 20px;
}
.list-timeline > li {
  margin-bottom: 10px;
}
.list-timeline .list-timeline-time {
  margin: 0;
  padding: 10px 20px;
  min-height: 50px;
  text-align: right;
  color: #999;
  line-height: 30px;
  font-style: italic;
  background-color: #f6f7f9;
  border-radius: 2px;
}
.list-timeline .list-timeline-icon {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}
.list-timeline .list-timeline-content {
  padding: 10px 10px 1px;
}
.list-timeline .list-timeline-content > p:first-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .list-timeline-modern::before {
    position: absolute;
    top: 0;
    left: 119px;
    bottom: 0;
    display: block;
    width: 6px;
    content: "";
    background-color: #f6f7f9;
    z-index: 1;
  }
  .list-timeline-modern > li {
    min-height: 40px;
    z-index: 2;
  }
  .list-timeline-modern > li:last-child {
    margin-bottom: 0;
  }
  .list-timeline-modern .list-timeline-time {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding-right: 0;
    padding-left: 0;
    width: 90px;
    font-size: 0.928571429rem;
    line-height: normal;
    background-color: transparent;
  }
  .list-timeline-modern .list-timeline-icon {
    top: 3px;
    left: 105px;
    z-index: 2 !important;
  }
  .list-timeline-modern .list-timeline-content {
    padding-left: 155px;
  }
}
.list-activity > li {
  margin-bottom: 10px;
  padding-bottom: 7px;
  padding-left: 35px;
  border-bottom: 1px solid #f0f2f5;
}
.list-activity > li > i:first-child {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
}
.list-activity > li:last-child {
  border-bottom: none;
}

.list-events > li {
  margin-bottom: 0.25rem;
}
.list-events .js-event {
  background-color: #26c6da;
  transition: transform 0.3s ease-out;
}
.list-events .js-event::before {
  position: absolute;
  top: 50%;
  right: auto;
  left: auto;
  transform: translateX(0) translateY(-50%);
  right: 10px;
  display: inline-block;
  font-family: "FontAwesome", "Font Awesome 5 Free", "Font Awesome 5 Pro";
  color: rgba(255, 255, 255, 0.5);
  content: "";
}
.list-events .js-event:hover {
  cursor: move;
  transform: translateX(-0.25rem);
}

.chat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.chat-list-item {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.chat-list-item:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.chat-list-item:last-child {
  border-bottom: none;
}

.item {
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  line-height: 60px;
}
a.item:hover, a.item:focus {
  opacity: 0.6;
}

.item.item-circle {
  border-radius: 50%;
}
.item.item-rounded {
  border-radius: 3px;
}
.item.item-rounded-big {
  border-radius: 24px;
}
.item.item-rounded-big.item-2x {
  border-radius: 35px;
}
.item.item-rounded-big.item-3x {
  border-radius: 50px;
}
.item.item-2x {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.item.item-3x {
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.ribbon {
  position: relative;
  min-height: 52px;
}
.ribbon-box {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: 600;
  z-index: 2;
}
.ribbon-box::before {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: "";
}
.ribbon-bookmark .ribbon-box {
  padding-left: 10px;
}
.ribbon-bookmark .ribbon-box::before {
  top: 0;
  right: 100%;
  height: 32px;
  border: 16px solid;
  border-left-width: 8px;
  border-right-width: 0;
}
.ribbon-modern .ribbon-box {
  top: 10px;
  right: -8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 16px;
}
.ribbon-modern .ribbon-box::before {
  display: none;
}
.ribbon-left .ribbon-box {
  right: auto;
  left: 0;
}
.ribbon-left.ribbon-bookmark .ribbon-box {
  padding-left: 12px;
  padding-right: 10px;
}
.ribbon-left.ribbon-bookmark .ribbon-box::before {
  right: auto;
  left: 100%;
  border-left-width: 0;
  border-right-width: 8px;
}
.ribbon-left.ribbon-modern .ribbon-box {
  left: -8px;
}
.ribbon-bottom .ribbon-box {
  top: auto;
  bottom: 10px;
}
.ribbon-primary .ribbon-box {
  background-color: #3f9ce8;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #3f9ce8;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #3f9ce8;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-modern .ribbon-box::before {
  border-color: #3f9ce8;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #3f9ce8;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #3f9ce8;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #3f9ce8;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-success .ribbon-box {
  background-color: #9ccc65;
}
.ribbon-success.ribbon-bookmark .ribbon-box::before {
  border-color: #9ccc65;
  border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #9ccc65;
  border-right-color: transparent;
}
.ribbon-success.ribbon-modern .ribbon-box::before {
  border-color: #9ccc65;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #9ccc65;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #9ccc65;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #9ccc65;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-info .ribbon-box {
  background-color: #26c6da;
}
.ribbon-info.ribbon-bookmark .ribbon-box::before {
  border-color: #26c6da;
  border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #26c6da;
  border-right-color: transparent;
}
.ribbon-info.ribbon-modern .ribbon-box::before {
  border-color: #26c6da;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #26c6da;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #26c6da;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #26c6da;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-warning .ribbon-box {
  background-color: #ffca28;
}
.ribbon-warning.ribbon-bookmark .ribbon-box::before {
  border-color: #ffca28;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #ffca28;
  border-right-color: transparent;
}
.ribbon-warning.ribbon-modern .ribbon-box::before {
  border-color: #ffca28;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #ffca28;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #ffca28;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #ffca28;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-danger .ribbon-box {
  background-color: #ef5350;
}
.ribbon-danger.ribbon-bookmark .ribbon-box::before {
  border-color: #ef5350;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #ef5350;
  border-right-color: transparent;
}
.ribbon-danger.ribbon-modern .ribbon-box::before {
  border-color: #ef5350;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-bottom .ribbon-box::before {
  border-color: #ef5350;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-left .ribbon-box::before {
  border-color: #ef5350;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-modern.ribbon-left.ribbon-bottom .ribbon-box::before {
  border-color: #ef5350;
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-crystal .ribbon-box {
  background-color: rgba(255, 255, 255, 0.35);
}
.ribbon-crystal.ribbon-bookmark .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.35);
  border-left-color: transparent;
}
.ribbon-crystal.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: rgba(255, 255, 255, 0.35);
  border-right-color: transparent;
}

.options-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
}
.options-container .options-item {
  transition: transform 0.35s ease-out;
  will-change: transform;
}
.options-container .options-overlay {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 2;
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  will-change: opacity, transform;
}
.options-container .options-overlay-content {
  position: absolute;
  top: 50%;
  right: auto;
  left: auto;
  transform: translateX(0) translateY(-50%);
  right: 0;
  left: 0;
  text-align: center;
}
.options-container:hover .options-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767.98px) {
  .options-container .options-overlay {
    display: none;
  }
  .options-container:hover .options-overlay {
    display: block;
  }
}

.fx-item-zoom-in:hover .options-item {
  transform: scale(1.2, 1.2);
}

.fx-item-rotate-r:hover .options-item {
  transform: scale(1.4) rotate(8deg);
}

.fx-item-rotate-l:hover .options-item {
  transform: scale(1.4) rotate(-8deg);
}

.fx-overlay-slide-top .options-overlay {
  transform: translateY(100%);
}
.fx-overlay-slide-top:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-right .options-overlay {
  transform: translateX(-100%);
}
.fx-overlay-slide-right:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-slide-down .options-overlay {
  transform: translateY(-100%);
}
.fx-overlay-slide-down:hover .options-overlay {
  transform: translateY(0);
}

.fx-overlay-slide-left .options-overlay {
  transform: translateX(100%);
}
.fx-overlay-slide-left:hover .options-overlay {
  transform: translateX(0);
}

.fx-overlay-zoom-in .options-overlay {
  transform: scale(0, 0);
}
.fx-overlay-zoom-in:hover .options-overlay {
  transform: scale(1, 1);
}

.fx-overlay-zoom-out .options-overlay {
  transform: scale(2, 2);
}
.fx-overlay-zoom-out:hover .options-overlay {
  transform: scale(1, 1);
}

.scrumboard {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  padding: 12px 12px 1px;
}
@media (min-width: 768px) {
  .scrumboard {
    padding: 24px 24px 1px;
  }
}
.scrumboard .scrumboard-col {
  flex: 0 0 auto;
  width: 320px;
  margin-right: 12px;
}
@media (min-width: 768px) {
  .scrumboard .scrumboard-col {
    margin-right: 24px;
  }
}
.scrumboard .scrumboard-item {
  position: relative;
  min-height: 42px;
  padding: 10px 87px 10px 10px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #a87e00;
  background-color: #fcf7e6;
  border-bottom: 1px solid rgba(168, 126, 0, 0.1);
  box-shadow: 0 5px 8px rgba(168, 126, 0, 0.05);
}
.scrumboard .scrumboard-item-options {
  position: absolute;
  top: 7px;
  right: 7px;
}
.scrumboard .scrumboard-item-handler {
  cursor: move;
}
.scrumboard .scrumboard-item-placeholder {
  min-height: 42px;
  border: 1px dashed #ffca28;
}

@media print {
  #page-container,
#main-container {
    padding: 0 !important;
  }

  #page-header,
#page-footer,
#page-loader,
#sidebar,
#side-overlay,
.block-options {
    display: none !important;
  }
}
.bg-primary-op {
  background-color: rgba(63, 156, 232, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(26, 132, 218, 0.75) !important;
}

.bg-primary-dark {
  background-color: #343a40 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #1d2124 !important;
}

.bg-primary-dark-op {
  background-color: rgba(52, 58, 64, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(29, 33, 36, 0.8) !important;
}

.bg-primary-darker {
  background-color: #212529 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #0a0c0d !important;
}

.bg-primary-light {
  background-color: #83bff0 !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #56a8eb !important;
}

.bg-primary-lighter {
  background-color: #c8e2f8 !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #9acbf3 !important;
}

.bg-body {
  background-color: #f0f2f5 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #d1d7e1 !important;
}

.bg-body-light {
  background-color: #f6f7f9 !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #d8dde5 !important;
}

.bg-body-dark {
  background-color: #e4e7ed !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #c5cdd8 !important;
}

.bg-muted {
  background-color: #6c757d !important;
}

a.bg-muted:hover, a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
  background-color: #545b62 !important;
}

.bg-success-light {
  background-color: #ebf5df !important;
}

a.bg-success-light:hover, a.bg-success-light:focus,
button.bg-success-light:hover,
button.bg-success-light:focus {
  background-color: #d3e9b8 !important;
}

.bg-warning-light {
  background-color: #fcf7e6 !important;
}

a.bg-warning-light:hover, a.bg-warning-light:focus,
button.bg-warning-light:hover,
button.bg-warning-light:focus {
  background-color: #f7e8b8 !important;
}

.bg-info-light {
  background-color: #e3f4fc !important;
}

a.bg-info-light:hover, a.bg-info-light:focus,
button.bg-info-light:hover,
button.bg-info-light:focus {
  background-color: #b5e2f7 !important;
}

.bg-danger-light {
  background-color: #fae9e8 !important;
}

a.bg-danger-light:hover, a.bg-danger-light:focus,
button.bg-danger-light:hover,
button.bg-danger-light:focus {
  background-color: #f1c1be !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-gray {
  background-color: #ced4da !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #b1bbc4 !important;
}

.bg-gray-dark {
  background-color: #6c757d !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #545b62 !important;
}

.bg-gray-darker {
  background-color: #343a40 !important;
}

a.bg-gray-darker:hover, a.bg-gray-darker:focus,
button.bg-gray-darker:hover,
button.bg-gray-darker:focus {
  background-color: #1d2124 !important;
}

.bg-gray-light {
  background-color: #e9ecef !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #cbd3da !important;
}

.bg-gray-lighter {
  background-color: #f8f9fa !important;
}

a.bg-gray-lighter:hover, a.bg-gray-lighter:focus,
button.bg-gray-lighter:hover,
button.bg-gray-lighter:focus {
  background-color: #dae0e5 !important;
}

.bg-gd-primary {
  background: #1776c3;
  background: linear-gradient(135deg, #1776c3 0%, #56a8eb 100%) !important;
}

.bg-gd-dusk {
  background: #d262e3;
  background: linear-gradient(135deg, #d262e3 0%, #3f9ce8 100%) !important;
}

.bg-gd-cherry {
  background: #ea6153;
  background: linear-gradient(135deg, #ea6153 0%, #d62c1a 100%) !important;
}

.bg-gd-aqua {
  background: #2facb2;
  background: linear-gradient(135deg, #2facb2 0%, #70b29c 100%) !important;
}

.bg-gd-emerald {
  background: #6eab4d;
  background: linear-gradient(135deg, #6eab4d 0%, #2facb2 100%) !important;
}

.bg-gd-sea {
  background: #1f8ce4;
  background: linear-gradient(135deg, #1f8ce4 0%, #26c6da 100%) !important;
}

.bg-gd-leaf {
  background: #ffca28;
  background: linear-gradient(135deg, #ffca28 0%, #9ccc65 100%) !important;
}

.bg-gd-lake {
  background: #26c6da;
  background: linear-gradient(135deg, #26c6da 0%, #9ccc65 100%) !important;
}

.bg-gd-sun {
  background: #ef5350;
  background: linear-gradient(135deg, #ef5350 0%, #ffca28 100%) !important;
}

.bg-gd-light {
  background: #f6f7f9;
  background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%) !important;
}

.bg-black-op {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.bg-black-op-5 {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.bg-black-op-10 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.bg-black-op-25 {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.bg-black-op-75 {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.bg-black-op-90 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.bg-black-op-95 {
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.bg-white-op {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.bg-white-op-5 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.bg-white-op-10 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.bg-white-op-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
.bg-white-op-75 {
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.bg-white-op-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.bg-white-op-95 {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.bg-image {
  background-color: #f8f9fa;
  background-position: 0% 50%;
  background-size: cover;
}
.bg-image-top {
  background-position-y: 0%;
}
.bg-image-bottom {
  background-position-y: 100%;
}
@media (min-width: 1200px) {
  .bg-image-fixed {
    background-attachment: fixed;
  }
}

.bg-pattern {
  background-repeat: repeat;
}

.bg-video {
  width: 100%;
  transform: translateZ(0);
}

.border {
  border: 1px solid #e4e7ed !important;
}
.border-t {
  border-top: 1px solid #e4e7ed !important;
}
.border-r {
  border-right: 1px solid #e4e7ed !important;
}
.border-b {
  border-bottom: 1px solid #e4e7ed !important;
}
.border-l {
  border-left: 1px solid #e4e7ed !important;
}

.border-light {
  border: 1px solid #f6f7f9 !important;
}
.border-light-t {
  border-top: 1px solid #f6f7f9 !important;
}
.border-light-r {
  border-right: 1px solid #f6f7f9 !important;
}
.border-light-b {
  border-bottom: 1px solid #f6f7f9 !important;
}
.border-light-l {
  border-left: 1px solid #f6f7f9 !important;
}

.border-white-op {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-t {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-r {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-b {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-white-op-l {
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-t {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-r {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-black-op-l {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-2x {
  border-width: 2px !important;
}

.border-3x {
  border-width: 3px !important;
}

.border-4x {
  border-width: 4px !important;
}

.border-5x {
  border-width: 5px !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.click-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  transform: scale(0);
}
.click-ripple.animate {
  animation: click-ripple 0.9s ease-out;
}

@keyframes click-ripple {
  100% {
    opacity: 0;
    transform: scale(3);
  }
}
.pos-t-5 {
  top: 5% !important;
}

.pos-t-10 {
  top: 10% !important;
}

.pos-t-20 {
  top: 20% !important;
}

.pos-t-25 {
  top: 25% !important;
}

.pos-t-30 {
  top: 30% !important;
}

.pos-t-40 {
  top: 40% !important;
}

.pos-t-50 {
  top: 50% !important;
}

.pos-t-60 {
  top: 60% !important;
}

.pos-t-70 {
  top: 70% !important;
}

.pos-t-75 {
  top: 75% !important;
}

.pos-t-80 {
  top: 80% !important;
}

.pos-t-90 {
  top: 90% !important;
}

.pos-t-100 {
  top: 100% !important;
}

.pos-l-5 {
  left: 5% !important;
}

.pos-l-10 {
  left: 10% !important;
}

.pos-l-20 {
  left: 20% !important;
}

.pos-l-25 {
  left: 25% !important;
}

.pos-l-30 {
  left: 30% !important;
}

.pos-l-40 {
  left: 40% !important;
}

.pos-l-50 {
  left: 50% !important;
}

.pos-l-60 {
  left: 60% !important;
}

.pos-l-70 {
  left: 70% !important;
}

.pos-l-75 {
  left: 75% !important;
}

.pos-l-80 {
  left: 80% !important;
}

.pos-l-90 {
  left: 90% !important;
}

.pos-l-100 {
  left: 100% !important;
}

.min-width-20 {
  min-width: 20px !important;
}

.min-width-30 {
  min-width: 30px !important;
}

.min-width-50 {
  min-width: 50px !important;
}

.min-width-75 {
  min-width: 75px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.min-width-125 {
  min-width: 125px !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.min-width-175 {
  min-width: 175px !important;
}

.min-width-200 {
  min-width: 200px !important;
}

.min-width-300 {
  min-width: 300px !important;
}

.min-width-320 {
  min-width: 320px !important;
}

.min-height-20 {
  min-height: 20px !important;
}

.min-height-30 {
  min-height: 30px !important;
}

.min-height-50 {
  min-height: 50px !important;
}

.min-height-75 {
  min-height: 75px !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.min-height-125 {
  min-height: 125px !important;
}

.min-height-150 {
  min-height: 150px !important;
}

.min-height-175 {
  min-height: 175px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.min-height-400 {
  min-height: 400px !important;
}

.min-height-500 {
  min-height: 500px !important;
}

.wh-10 {
  width: 10px !important;
  height: 10px !important;
}

.wh-20 {
  width: 20px !important;
  height: 20px !important;
}

.wh-30 {
  width: 30px !important;
  height: 30px !important;
}

.wh-40 {
  width: 40px !important;
  height: 40px !important;
}

.wh-50 {
  width: 50px !important;
  height: 50px !important;
}

.m-5 {
  margin: 5px 5px !important;
}

.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mr-5,
.mx-5 {
  margin-right: 5px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.m-10 {
  margin: 10px 10px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px !important;
}

.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px !important;
}

.ml-10,
.mx-10 {
  margin-left: 10px !important;
}

.m-15 {
  margin: 15px 15px !important;
}

.mt-15,
.my-15 {
  margin-top: 15px !important;
}

.mr-15,
.mx-15 {
  margin-right: 15px !important;
}

.mb-15,
.my-15 {
  margin-bottom: 15px !important;
}

.ml-15,
.mx-15 {
  margin-left: 15px !important;
}

.m-20 {
  margin: 20px 20px !important;
}

.mt-20,
.my-20 {
  margin-top: 20px !important;
}

.mr-20,
.mx-20 {
  margin-right: 20px !important;
}

.mb-20,
.my-20 {
  margin-bottom: 20px !important;
}

.ml-20,
.mx-20 {
  margin-left: 20px !important;
}

.m-30 {
  margin: 30px 30px !important;
}

.mt-30,
.my-30 {
  margin-top: 30px !important;
}

.mr-30,
.mx-30 {
  margin-right: 30px !important;
}

.mb-30,
.my-30 {
  margin-bottom: 30px !important;
}

.ml-30,
.mx-30 {
  margin-left: 30px !important;
}

.m-50 {
  margin: 50px 50px !important;
}

.mt-50,
.my-50 {
  margin-top: 50px !important;
}

.mr-50,
.mx-50 {
  margin-right: 50px !important;
}

.mb-50,
.my-50 {
  margin-bottom: 50px !important;
}

.ml-50,
.mx-50 {
  margin-left: 50px !important;
}

.m-100 {
  margin: 100px 100px !important;
}

.mt-100,
.my-100 {
  margin-top: 100px !important;
}

.mr-100,
.mx-100 {
  margin-right: 100px !important;
}

.mb-100,
.my-100 {
  margin-bottom: 100px !important;
}

.ml-100,
.mx-100 {
  margin-left: 100px !important;
}

.m-150 {
  margin: 150px 150px !important;
}

.mt-150,
.my-150 {
  margin-top: 150px !important;
}

.mr-150,
.mx-150 {
  margin-right: 150px !important;
}

.mb-150,
.my-150 {
  margin-bottom: 150px !important;
}

.ml-150,
.mx-150 {
  margin-left: 150px !important;
}

.m-200 {
  margin: 200px 200px !important;
}

.mt-200,
.my-200 {
  margin-top: 200px !important;
}

.mr-200,
.mx-200 {
  margin-right: 200px !important;
}

.mb-200,
.my-200 {
  margin-bottom: 200px !important;
}

.ml-200,
.mx-200 {
  margin-left: 200px !important;
}

.m-300 {
  margin: 300px 300px !important;
}

.mt-300,
.my-300 {
  margin-top: 300px !important;
}

.mr-300,
.mx-300 {
  margin-right: 300px !important;
}

.mb-300,
.my-300 {
  margin-bottom: 300px !important;
}

.ml-300,
.mx-300 {
  margin-left: 300px !important;
}

.p-5 {
  padding: 5px 5px !important;
}

.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pr-5,
.px-5 {
  padding-right: 5px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pl-5,
.px-5 {
  padding-left: 5px !important;
}

.p-10 {
  padding: 10px 10px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px !important;
}

.pr-10,
.px-10 {
  padding-right: 10px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px !important;
}

.pl-10,
.px-10 {
  padding-left: 10px !important;
}

.p-15 {
  padding: 15px 15px !important;
}

.pt-15,
.py-15 {
  padding-top: 15px !important;
}

.pr-15,
.px-15 {
  padding-right: 15px !important;
}

.pb-15,
.py-15 {
  padding-bottom: 15px !important;
}

.pl-15,
.px-15 {
  padding-left: 15px !important;
}

.p-20 {
  padding: 20px 20px !important;
}

.pt-20,
.py-20 {
  padding-top: 20px !important;
}

.pr-20,
.px-20 {
  padding-right: 20px !important;
}

.pb-20,
.py-20 {
  padding-bottom: 20px !important;
}

.pl-20,
.px-20 {
  padding-left: 20px !important;
}

.p-30 {
  padding: 30px 30px !important;
}

.pt-30,
.py-30 {
  padding-top: 30px !important;
}

.pr-30,
.px-30 {
  padding-right: 30px !important;
}

.pb-30,
.py-30 {
  padding-bottom: 30px !important;
}

.pl-30,
.px-30 {
  padding-left: 30px !important;
}

.p-50 {
  padding: 50px 50px !important;
}

.pt-50,
.py-50 {
  padding-top: 50px !important;
}

.pr-50,
.px-50 {
  padding-right: 50px !important;
}

.pb-50,
.py-50 {
  padding-bottom: 50px !important;
}

.pl-50,
.px-50 {
  padding-left: 50px !important;
}

.p-100 {
  padding: 100px 100px !important;
}

.pt-100,
.py-100 {
  padding-top: 100px !important;
}

.pr-100,
.px-100 {
  padding-right: 100px !important;
}

.pb-100,
.py-100 {
  padding-bottom: 100px !important;
}

.pl-100,
.px-100 {
  padding-left: 100px !important;
}

.p-150 {
  padding: 150px 150px !important;
}

.pt-150,
.py-150 {
  padding-top: 150px !important;
}

.pr-150,
.px-150 {
  padding-right: 150px !important;
}

.pb-150,
.py-150 {
  padding-bottom: 150px !important;
}

.pl-150,
.px-150 {
  padding-left: 150px !important;
}

.p-200 {
  padding: 200px 200px !important;
}

.pt-200,
.py-200 {
  padding-top: 200px !important;
}

.pr-200,
.px-200 {
  padding-right: 200px !important;
}

.pb-200,
.py-200 {
  padding-bottom: 200px !important;
}

.pl-200,
.px-200 {
  padding-left: 200px !important;
}

.p-300 {
  padding: 300px 300px !important;
}

.pt-300,
.py-300 {
  padding-top: 300px !important;
}

.pr-300,
.px-300 {
  padding-right: 300px !important;
}

.pb-300,
.py-300 {
  padding-bottom: 300px !important;
}

.pl-300,
.px-300 {
  padding-left: 300px !important;
}

.push,
.items-push > div {
  margin-bottom: 24px;
}

.font-w300 {
  font-weight: 300 !important;
}

.font-w400 {
  font-weight: 400 !important;
}

.font-w600 {
  font-weight: 600 !important;
}

.font-w700 {
  font-weight: 700 !important;
}

.font-size-default {
  font-size: 1rem !important;
}

.font-size-xl {
  font-size: 1.428571429rem !important;
}

.font-size-lg {
  font-size: 1.285714286rem !important;
}

.font-size-md {
  font-size: 1.142857143rem !important;
}

.font-size-sm {
  font-size: 0.928571429rem !important;
}

.font-size-xs {
  font-size: 0.857142857rem !important;
}

.line-height-base {
  line-height: 1.5 !important;
}

.text-wrap-break-word {
  word-wrap: break-word;
}

.text-uppercase {
  letter-spacing: 0.05rem;
}

.text-back {
  position: absolute;
  top: -3rem;
  right: 0;
  font-size: 500%;
  font-weight: 700;
  opacity: 0.05;
  z-index: 0;
}

.text-primary-dark {
  color: #343a40 !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #121416 !important;
}

.text-primary-darker {
  color: #212529 !important;
}

a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: black !important;
}

.text-primary-light {
  color: #83bff0 !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #3f9ce8 !important;
}

.text-primary-lighter {
  color: #c8e2f8 !important;
}

a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #83bff0 !important;
}

.text-body-bg {
  color: #f0f2f5 !important;
}

a.text-body-bg:hover, a.text-body-bg:focus {
  color: #c2cad6 !important;
}

.text-body-bg-dark {
  color: #e4e7ed !important;
}

a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #b6c0ce !important;
}

.text-body-bg-light {
  color: #f6f7f9 !important;
}

a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #c8d0da !important;
}

.text-body-color {
  color: #575757 !important;
}

a.text-body-color:hover, a.text-body-color:focus {
  color: #313131 !important;
}

.text-body-color-dark {
  color: #171717 !important;
}

a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: black !important;
}

.text-body-color-light {
  color: #e4e7ed !important;
}

a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #b6c0ce !important;
}

.text-muted {
  color: #6c757d !important;
}

a.text-muted:hover, a.text-muted:focus {
  color: #494f54 !important;
}

.text-success-light {
  color: #ebf5df !important;
}

a.text-success-light:hover, a.text-success-light:focus {
  color: #c7e3a5 !important;
}

.text-warning-light {
  color: #fcf7e6 !important;
}

a.text-warning-light:hover, a.text-warning-light:focus {
  color: #f4e1a2 !important;
}

.text-info-light {
  color: #e3f4fc !important;
}

a.text-info-light:hover, a.text-info-light:focus {
  color: #9ed9f5 !important;
}

.text-danger-light {
  color: #fae9e8 !important;
}

a.text-danger-light:hover, a.text-danger-light:focus {
  color: #ecada9 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-white-op {
  color: rgba(255, 255, 255, 0.8) !important;
}

a.text-white-op:hover, a.text-white-op:focus {
  color: rgba(217, 217, 217, 0.8) !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-black-op {
  color: rgba(0, 0, 0, 0.5) !important;
}

a.text-black-op:hover, a.text-black-op:focus {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-gray {
  color: #ced4da !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #a2aeb9 !important;
}

.text-gray-dark {
  color: #6c757d !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #494f54 !important;
}

.text-gray-darker {
  color: #343a40 !important;
}

a.text-gray-darker:hover, a.text-gray-darker:focus {
  color: #121416 !important;
}

.text-gray-light {
  color: #e9ecef !important;
}

a.text-gray-light:hover, a.text-gray-light:focus {
  color: #bdc6cf !important;
}

.text-gray-lighter {
  color: #f8f9fa !important;
}

a.text-gray-lighter:hover, a.text-gray-lighter:focus {
  color: #cbd3da !important;
}

.text-dual-primary-dark {
  color: #343a40 !important;
}

a.text-dual-primary-dark:hover, a.text-dual-primary-dark:focus {
  color: #121416 !important;
}

.page-header-inverse #page-header .text-dual-primary-dark,
.sidebar-inverse #sidebar .text-dual-primary-dark {
  color: #e4e7ed !important;
}
.page-header-inverse #page-header a.text-dual-primary-dark:hover, .page-header-inverse #page-header a.text-dual-primary-dark:focus,
.sidebar-inverse #sidebar a.text-dual-primary-dark:hover,
.sidebar-inverse #sidebar a.text-dual-primary-dark:focus {
  color: #b6c0ce !important;
}

.align-parent {
  position: relative;
}

.align-v {
  position: absolute;
  top: 50%;
  right: auto;
  left: auto;
  transform: translateX(0) translateY(-50%);
}

.align-v-r {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  transform: translateX(0) translateY(-50%);
}

.align-v-l {
  position: absolute;
  top: 50%;
  right: auto;
  left: 0;
  transform: translateX(0) translateY(-50%);
}

.align-v-r {
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  transform: translateX(0) translateY(-50%);
}

.align-v-h {
  position: absolute;
  top: 50%;
  right: auto;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.text-default {
  color: #3f9ce8 !important;
}

a.text-default:hover, a.text-default:focus {
  color: #1776c3 !important;
}

.text-default-dark {
  color: #343a40 !important;
}

a.text-default-dark:hover, a.text-default-dark:focus {
  color: #121416 !important;
}

.text-default-darker {
  color: #212529 !important;
}

a.text-default-darker:hover, a.text-default-darker:focus {
  color: black !important;
}

.text-default-light {
  color: #83bff0 !important;
}

a.text-default-light:hover, a.text-default-light:focus {
  color: #3f9ce8 !important;
}

.text-default-lighter {
  color: #c8e2f8 !important;
}

a.text-default-lighter:hover, a.text-default-lighter:focus {
  color: #83bff0 !important;
}

.bg-default {
  background-color: #3f9ce8 !important;
}

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #1a84da !important;
}

.bg-default-op {
  background-color: rgba(63, 156, 232, 0.75) !important;
}

a.bg-default-op:hover, a.bg-default-op:focus,
button.bg-default-op:hover,
button.bg-default-op:focus {
  background-color: rgba(26, 132, 218, 0.75) !important;
}

.bg-default-dark {
  background-color: #343a40 !important;
}

a.bg-default-dark:hover, a.bg-default-dark:focus,
button.bg-default-dark:hover,
button.bg-default-dark:focus {
  background-color: #1d2124 !important;
}

.bg-default-dark-op {
  background-color: rgba(52, 58, 64, 0.83) !important;
}

a.bg-default-dark-op:hover, a.bg-default-dark-op:focus,
button.bg-default-dark-op:hover,
button.bg-default-dark-op:focus {
  background-color: rgba(29, 33, 36, 0.83) !important;
}

.bg-default-darker {
  background-color: #212529 !important;
}

a.bg-default-darker:hover, a.bg-default-darker:focus,
button.bg-default-darker:hover,
button.bg-default-darker:focus {
  background-color: #0a0c0d !important;
}

.bg-default-light {
  background-color: #83bff0 !important;
}

a.bg-default-light:hover, a.bg-default-light:focus,
button.bg-default-light:hover,
button.bg-default-light:focus {
  background-color: #56a8eb !important;
}

.bg-default-lighter {
  background-color: #c8e2f8 !important;
}

a.bg-default-lighter:hover, a.bg-default-lighter:focus,
button.bg-default-lighter:hover,
button.bg-default-lighter:focus {
  background-color: #9acbf3 !important;
}

.bg-gd-default {
  background: #1776c3;
  background: linear-gradient(135deg, #1776c3 0%, #56a8eb 100%) !important;
}

.text-elegance {
  color: #d262e3 !important;
}

a.text-elegance:hover, a.text-elegance:focus {
  color: #bc26d3 !important;
}

.text-elegance-dark {
  color: #403537 !important;
}

a.text-elegance-dark:hover, a.text-elegance-dark:focus {
  color: #161213 !important;
}

.text-elegance-darker {
  color: #332a2c !important;
}

a.text-elegance-darker:hover, a.text-elegance-darker:focus {
  color: #090708 !important;
}

.text-elegance-light {
  color: #e196ec !important;
}

a.text-elegance-light:hover, a.text-elegance-light:focus {
  color: #ce55e1 !important;
}

.text-elegance-lighter {
  color: #edc1f4 !important;
}

a.text-elegance-lighter:hover, a.text-elegance-lighter:focus {
  color: #db80e8 !important;
}

.bg-elegance {
  background-color: #d262e3 !important;
}

a.bg-elegance:hover, a.bg-elegance:focus,
button.bg-elegance:hover,
button.bg-elegance:focus {
  background-color: #c637db !important;
}

.bg-elegance-op {
  background-color: rgba(210, 98, 227, 0.75) !important;
}

a.bg-elegance-op:hover, a.bg-elegance-op:focus,
button.bg-elegance-op:hover,
button.bg-elegance-op:focus {
  background-color: rgba(198, 55, 219, 0.75) !important;
}

.bg-elegance-dark {
  background-color: #403537 !important;
}

a.bg-elegance-dark:hover, a.bg-elegance-dark:focus,
button.bg-elegance-dark:hover,
button.bg-elegance-dark:focus {
  background-color: #241e1f !important;
}

.bg-elegance-dark-op {
  background-color: rgba(64, 53, 55, 0.83) !important;
}

a.bg-elegance-dark-op:hover, a.bg-elegance-dark-op:focus,
button.bg-elegance-dark-op:hover,
button.bg-elegance-dark-op:focus {
  background-color: rgba(36, 30, 31, 0.83) !important;
}

.bg-elegance-darker {
  background-color: #332a2c !important;
}

a.bg-elegance-darker:hover, a.bg-elegance-darker:focus,
button.bg-elegance-darker:hover,
button.bg-elegance-darker:focus {
  background-color: #171314 !important;
}

.bg-elegance-light {
  background-color: #e196ec !important;
}

a.bg-elegance-light:hover, a.bg-elegance-light:focus,
button.bg-elegance-light:hover,
button.bg-elegance-light:focus {
  background-color: #d46be5 !important;
}

.bg-elegance-lighter {
  background-color: #edc1f4 !important;
}

a.bg-elegance-lighter:hover, a.bg-elegance-lighter:focus,
button.bg-elegance-lighter:hover,
button.bg-elegance-lighter:focus {
  background-color: #e196ec !important;
}

.bg-gd-elegance {
  background: #bc26d3;
  background: linear-gradient(135deg, #bc26d3 0%, #d878e7 100%) !important;
}

.text-pulse {
  color: #e74c3c !important;
}

a.text-pulse:hover, a.text-pulse:focus {
  color: #bf2718 !important;
}

.text-pulse-dark {
  color: #383838 !important;
}

a.text-pulse-dark:hover, a.text-pulse-dark:focus {
  color: #121212 !important;
}

.text-pulse-darker {
  color: #232323 !important;
}

a.text-pulse-darker:hover, a.text-pulse-darker:focus {
  color: black !important;
}

.text-pulse-light {
  color: #eb6d60 !important;
}

a.text-pulse-light:hover, a.text-pulse-light:focus {
  color: #e32f1c !important;
}

.text-pulse-lighter {
  color: #f3a8a0 !important;
}

a.text-pulse-lighter:hover, a.text-pulse-lighter:focus {
  color: #eb695c !important;
}

.bg-pulse {
  background-color: #e74c3c !important;
}

a.bg-pulse:hover, a.bg-pulse:focus,
button.bg-pulse:hover,
button.bg-pulse:focus {
  background-color: #d62c1a !important;
}

.bg-pulse-op {
  background-color: rgba(231, 76, 60, 0.75) !important;
}

a.bg-pulse-op:hover, a.bg-pulse-op:focus,
button.bg-pulse-op:hover,
button.bg-pulse-op:focus {
  background-color: rgba(214, 44, 26, 0.75) !important;
}

.bg-pulse-dark {
  background-color: #383838 !important;
}

a.bg-pulse-dark:hover, a.bg-pulse-dark:focus,
button.bg-pulse-dark:hover,
button.bg-pulse-dark:focus {
  background-color: #1f1f1f !important;
}

.bg-pulse-dark-op {
  background-color: rgba(56, 56, 56, 0.83) !important;
}

a.bg-pulse-dark-op:hover, a.bg-pulse-dark-op:focus,
button.bg-pulse-dark-op:hover,
button.bg-pulse-dark-op:focus {
  background-color: rgba(31, 31, 31, 0.83) !important;
}

.bg-pulse-darker {
  background-color: #232323 !important;
}

a.bg-pulse-darker:hover, a.bg-pulse-darker:focus,
button.bg-pulse-darker:hover,
button.bg-pulse-darker:focus {
  background-color: #0a0a0a !important;
}

.bg-pulse-light {
  background-color: #eb6d60 !important;
}

a.bg-pulse-light:hover, a.bg-pulse-light:focus,
button.bg-pulse-light:hover,
button.bg-pulse-light:focus {
  background-color: #e64433 !important;
}

.bg-pulse-lighter {
  background-color: #f3a8a0 !important;
}

a.bg-pulse-lighter:hover, a.bg-pulse-lighter:focus,
button.bg-pulse-lighter:hover,
button.bg-pulse-lighter:focus {
  background-color: #ee7e72 !important;
}

.bg-gd-pulse {
  background: #bf2718;
  background: linear-gradient(135deg, #bf2718 0%, #ea6153 100%) !important;
}

.text-flat {
  color: #70b29c !important;
}

a.text-flat:hover, a.text-flat:focus {
  color: #4b8b75 !important;
}

.text-flat-dark {
  color: #113f4c !important;
}

a.text-flat-dark:hover, a.text-flat-dark:focus {
  color: #030b0d !important;
}

.text-flat-darker {
  color: #0b2830 !important;
}

a.text-flat-darker:hover, a.text-flat-darker:focus {
  color: black !important;
}

.text-flat-light {
  color: #acd2c5 !important;
}

a.text-flat-light:hover, a.text-flat-light:focus {
  color: #7ab7a3 !important;
}

.text-flat-lighter {
  color: #cde4dc !important;
}

a.text-flat-lighter:hover, a.text-flat-lighter:focus {
  color: #9bc9ba !important;
}

.bg-flat {
  background-color: #70b29c !important;
}

a.bg-flat:hover, a.bg-flat:focus,
button.bg-flat:hover,
button.bg-flat:focus {
  background-color: #549b83 !important;
}

.bg-flat-op {
  background-color: rgba(112, 178, 156, 0.75) !important;
}

a.bg-flat-op:hover, a.bg-flat-op:focus,
button.bg-flat-op:hover,
button.bg-flat-op:focus {
  background-color: rgba(84, 155, 131, 0.75) !important;
}

.bg-flat-dark {
  background-color: #113f4c !important;
}

a.bg-flat-dark:hover, a.bg-flat-dark:focus,
button.bg-flat-dark:hover,
button.bg-flat-dark:focus {
  background-color: #081c22 !important;
}

.bg-flat-dark-op {
  background-color: rgba(17, 63, 76, 0.83) !important;
}

a.bg-flat-dark-op:hover, a.bg-flat-dark-op:focus,
button.bg-flat-dark-op:hover,
button.bg-flat-dark-op:focus {
  background-color: rgba(8, 28, 34, 0.83) !important;
}

.bg-flat-darker {
  background-color: #0b2830 !important;
}

a.bg-flat-darker:hover, a.bg-flat-darker:focus,
button.bg-flat-darker:hover,
button.bg-flat-darker:focus {
  background-color: #010507 !important;
}

.bg-flat-light {
  background-color: #acd2c5 !important;
}

a.bg-flat-light:hover, a.bg-flat-light:focus,
button.bg-flat-light:hover,
button.bg-flat-light:focus {
  background-color: #8bc0ae !important;
}

.bg-flat-lighter {
  background-color: #cde4dc !important;
}

a.bg-flat-lighter:hover, a.bg-flat-lighter:focus,
button.bg-flat-lighter:hover,
button.bg-flat-lighter:focus {
  background-color: #acd2c5 !important;
}

.bg-gd-flat {
  background: #4b8b75;
  background: linear-gradient(135deg, #4b8b75 0%, #81bba7 100%) !important;
}

.text-corporate {
  color: #2facb2 !important;
}

a.text-corporate:hover, a.text-corporate:focus {
  color: #1f7275 !important;
}

.text-corporate-dark {
  color: #323d4c !important;
}

a.text-corporate-dark:hover, a.text-corporate-dark:focus {
  color: #14181e !important;
}

.text-corporate-darker {
  color: #252d38 !important;
}

a.text-corporate-darker:hover, a.text-corporate-darker:focus {
  color: #07080a !important;
}

.text-corporate-light {
  color: #5aced3 !important;
}

a.text-corporate-light:hover, a.text-corporate-light:focus {
  color: #2facb2 !important;
}

.text-corporate-lighter {
  color: #d3f2f3 !important;
}

a.text-corporate-lighter:hover, a.text-corporate-lighter:focus {
  color: #97e0e3 !important;
}

.bg-corporate {
  background-color: #2facb2 !important;
}

a.bg-corporate:hover, a.bg-corporate:focus,
button.bg-corporate:hover,
button.bg-corporate:focus {
  background-color: #24858a !important;
}

.bg-corporate-op {
  background-color: rgba(47, 172, 178, 0.75) !important;
}

a.bg-corporate-op:hover, a.bg-corporate-op:focus,
button.bg-corporate-op:hover,
button.bg-corporate-op:focus {
  background-color: rgba(36, 133, 138, 0.75) !important;
}

.bg-corporate-dark {
  background-color: #323d4c !important;
}

a.bg-corporate-dark:hover, a.bg-corporate-dark:focus,
button.bg-corporate-dark:hover,
button.bg-corporate-dark:focus {
  background-color: #1e242d !important;
}

.bg-corporate-dark-op {
  background-color: rgba(50, 61, 76, 0.83) !important;
}

a.bg-corporate-dark-op:hover, a.bg-corporate-dark-op:focus,
button.bg-corporate-dark-op:hover,
button.bg-corporate-dark-op:focus {
  background-color: rgba(30, 36, 45, 0.83) !important;
}

.bg-corporate-darker {
  background-color: #252d38 !important;
}

a.bg-corporate-darker:hover, a.bg-corporate-darker:focus,
button.bg-corporate-darker:hover,
button.bg-corporate-darker:focus {
  background-color: #111419 !important;
}

.bg-corporate-light {
  background-color: #5aced3 !important;
}

a.bg-corporate-light:hover, a.bg-corporate-light:focus,
button.bg-corporate-light:hover,
button.bg-corporate-light:focus {
  background-color: #34bfc6 !important;
}

.bg-corporate-lighter {
  background-color: #d3f2f3 !important;
}

a.bg-corporate-lighter:hover, a.bg-corporate-lighter:focus,
button.bg-corporate-lighter:hover,
button.bg-corporate-lighter:focus {
  background-color: #abe6e9 !important;
}

.bg-gd-corporate {
  background: #1f7275;
  background: linear-gradient(135deg, #1f7275 0%, #34bfc6 100%) !important;
}

.text-earth {
  color: #6eab4d !important;
}

a.text-earth:hover, a.text-earth:focus {
  color: #4c7635 !important;
}

.text-earth-dark {
  color: #424242 !important;
}

a.text-earth-dark:hover, a.text-earth-dark:focus {
  color: #1c1c1c !important;
}

.text-earth-darker {
  color: #222 !important;
}

a.text-earth-darker:hover, a.text-earth-darker:focus {
  color: black !important;
}

.text-earth-light {
  color: #a0ca8a !important;
}

a.text-earth-light:hover, a.text-earth-light:focus {
  color: #76b255 !important;
}

.text-earth-lighter {
  color: #e4f0de !important;
}

a.text-earth-lighter:hover, a.text-earth-lighter:focus {
  color: #bad8a9 !important;
}

.bg-earth {
  background-color: #6eab4d !important;
}

a.bg-earth:hover, a.bg-earth:focus,
button.bg-earth:hover,
button.bg-earth:focus {
  background-color: #57883d !important;
}

.bg-earth-op {
  background-color: rgba(110, 171, 77, 0.75) !important;
}

a.bg-earth-op:hover, a.bg-earth-op:focus,
button.bg-earth-op:hover,
button.bg-earth-op:focus {
  background-color: rgba(87, 136, 61, 0.75) !important;
}

.bg-earth-dark {
  background-color: #424242 !important;
}

a.bg-earth-dark:hover, a.bg-earth-dark:focus,
button.bg-earth-dark:hover,
button.bg-earth-dark:focus {
  background-color: #292929 !important;
}

.bg-earth-dark-op {
  background-color: rgba(66, 66, 66, 0.83) !important;
}

a.bg-earth-dark-op:hover, a.bg-earth-dark-op:focus,
button.bg-earth-dark-op:hover,
button.bg-earth-dark-op:focus {
  background-color: rgba(41, 41, 41, 0.83) !important;
}

.bg-earth-darker {
  background-color: #222 !important;
}

a.bg-earth-darker:hover, a.bg-earth-darker:focus,
button.bg-earth-darker:hover,
button.bg-earth-darker:focus {
  background-color: #090909 !important;
}

.bg-earth-light {
  background-color: #a0ca8a !important;
}

a.bg-earth-light:hover, a.bg-earth-light:focus,
button.bg-earth-light:hover,
button.bg-earth-light:focus {
  background-color: #84ba66 !important;
}

.bg-earth-lighter {
  background-color: #e4f0de !important;
}

a.bg-earth-lighter:hover, a.bg-earth-lighter:focus,
button.bg-earth-lighter:hover,
button.bg-earth-lighter:focus {
  background-color: #c8e0bb !important;
}

.bg-gd-earth {
  background: #4c7635;
  background: linear-gradient(135deg, #4c7635 0%, #7bb65c 100%) !important;
}

#page-container.rtl-support {
  direction: rtl;
  text-align: right;
}
#page-container.rtl-support .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}
@media (min-width: 992px) {
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar .content-side,
#page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar .content-header {
    transform: translateX(-176px) translateY(0) translateZ(0);
  }
  #page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:hover .content-side,
#page-container.rtl-support.sidebar-mini.sidebar-o.sidebar-r #sidebar:hover .content-header {
    transform: translateX(0);
  }
}
#page-container.rtl-support .nav-main a {
  padding-right: 40px;
  padding-left: 18px;
}
#page-container.rtl-support .nav-main a > i {
  right: 19px;
  left: auto;
}
#page-container.rtl-support .nav-main a.nav-submenu {
  padding-right: 40px;
  padding-left: 35px;
}
#page-container.rtl-support .nav-main a.nav-submenu::before, #page-container.rtl-support .nav-main a.nav-submenu::after {
  right: auto;
  left: 15px;
}
#page-container.rtl-support .nav-main a.nav-submenu::before {
  content: "";
}
#page-container.rtl-support .nav-main a.nav-submenu::after {
  content: "";
  transform: rotate(90deg);
}
#page-container.rtl-support .nav-main ul {
  padding-right: 40px;
  padding-left: 0;
}
#page-container.rtl-support .nav-main ul a,
#page-container.rtl-support .nav-main ul a.nav-submenu {
  padding-right: 0;
  padding-left: 8px;
}
#page-container.rtl-support .nav-main ul a > i {
  margin-right: 0;
  margin-left: 15px;
}
#page-container.rtl-support .nav-main ul ul {
  padding-right: 12px;
}
#page-container.rtl-support .nav-main li.open > a.nav-submenu::before {
  transform: rotate(90deg);
}
@media (min-width: 992px) {
  #page-container.rtl-support .nav-main-header a > i {
    margin-right: 0;
    margin-left: 8px;
  }
  #page-container.rtl-support .nav-main-header a.nav-submenu {
    padding-right: 14px;
    padding-left: 28px;
  }
  #page-container.rtl-support .nav-main-header a.nav-submenu::before {
    right: auto;
    left: 6px;
  }
  #page-container.rtl-support .nav-main-header ul {
    right: 0;
    left: auto;
  }
  #page-container.rtl-support .nav-main-header ul a.nav-submenu::before {
    content: "";
  }
  #page-container.rtl-support .nav-main-header ul ul {
    right: 100%;
    left: auto;
  }
  #page-container.rtl-support .nav-main-header > li:last-child ul {
    right: auto;
    left: 0;
  }
  #page-container.rtl-support .nav-main-header > li:last-child ul a.nav-submenu::before {
    content: "";
  }
  #page-container.rtl-support .nav-main-header > li:last-child ul ul {
    right: auto;
    left: 100%;
  }
}